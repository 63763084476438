import { apiRoutes } from '../../api-routes';
import {
  type ApiConfig,
  epHttpClient,
  type AxiosRequestConfig,
} from '../../util';
import { googleAddressResponseToAddress } from './utils';

export interface GoogleAddressResponse {
  address_components: AddressComponent[];
  formatted_address: string;
  geometry: GeometryDetail;
  place_id: string;
  plus_code: PlusCode;
  types: string[];
  sessiontoken: string;
  fromCache: boolean;
  name: string;
}

export interface AddressComponent {
  long_name: string;
  short_name: string;
  types: string[];
}

export interface GeometryDetail {
  location: {
    lat: number;
    lng: number;
  };
  location_type: string;
  viewport: Viewport;
}
export interface Viewport {
  northeast: any; // Location
  southwest: any; // Location
}
export interface PlusCode {
  compound_code: string;
  global_code: string;
}

export interface GoogleAddressParams {
  place_id: string;
  sessiontoken?: string;
}

export const getGoogleAddressFn = async (
  apiConfig: ApiConfig,
  params: GoogleAddressParams,
  config: AxiosRequestConfig = {}
) => {
  const response = await epHttpClient(apiConfig).get<GoogleAddressResponse>(
    apiRoutes.place.detail,
    {
      params,
      ...config,
    }
  );
  return googleAddressResponseToAddress(response.data);
};
