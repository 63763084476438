import { apiRoutes } from '../../api-routes';
import { httpClient, type AxiosRequestConfig } from '../../util/http-client';
import type { UploadOrderImageRequest, UploadOrderImageResponse } from './type';

export const uploadOrderImageFn = async (
  body: UploadOrderImageRequest,
  config: AxiosRequestConfig = {}
) => {
  const formData = new FormData();
  formData.append('file', body.file);

  if (body.fileType) {
    formData.append('file_type', body.fileType);
  }

  const response = await httpClient.post<UploadOrderImageResponse>(
    apiRoutes.assets.upload_order_image,
    formData,
    {
      ...config,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    }
  );
  return response.data;
};
