import { OrderStatus } from '../../constants';
import type { Path } from '../place';
import type { OrderTracking } from './type';

export function orderTrackingFromJSON(json: OrderTracking): OrderTracking {
  const payload = {
    ...json,
    order_id: json._id,
    order_has_failed_stop_point: false,
  };

  // setBoardedStatus(payload);
  findFailedStopPoint(payload, json);
  calculateStopPointAndSuccessPoint(payload, json);
  getReturnTime(payload, json.path);
  setOrderCompletedStatus(payload, json);

  if (
    // json.notify_package_return === true && // TODO: need verify
    json.status === OrderStatus.Completed &&
    payload.order_has_failed_stop_point
  ) {
    const returnTime = payload.path[0]?.return_time ?? payload.return_time;
    payload.returning_status_label = {
      returned: Boolean(returnTime),
    };
  }

  // if (json.rating_by_receiver) {
  //   commit(
  //     types.UPDATE_RATING_SUPPLIER,
  //     json.rating_by_receiver
  //   );
  // }

  // if (
  //   !state.calledApiToGetSupplierLocations &&
  //   input.enableGetSupplierLocations
  // ) {
  //   dispatch("getSupplierLocations", {
  //     order_id: json._id,
  //     from_time:
  //       json.accept_time || json.create_time,
  //     to_time: json.complete_time,
  //   });
  //   commit(types.CALLED_API_TO_GET_SUPPLIER_LOCATIONS, true);
  // }

  //  resolve(isOrderCompleted);

  return payload;
}

// function setBoardedStatus(payload: OrderTracking) {
//   if (
//     payload.status === OrderStatus.Accepted &&
//     payload.sub_status &&
//     payload.sub_status === OrderStatus.Boarded
//   ) {
//     payload.status = OrderStatus.Boarded;
//   }
// }

function findFailedStopPoint(payload: OrderTracking, json: OrderTracking) {
  const path = json.path;

  if (path) {
    if (path.length === 2 && path[1]) {
      payload.tracking_number = path[1].tracking_number;
      if (path[1].status === OrderStatus.Failed) {
        // payload.status = OrderStatus.Failed;
        payload.fail_time = path[1].fail_time;
        payload.order_has_failed_stop_point = true;
      }
    } else if (path.length > 2) {
      let failedPoint = 0;
      let successPoint = 0;
      path.forEach((point, index) => {
        if (index > 0) {
          if (point.status === OrderStatus.Failed) {
            payload.order_has_failed_stop_point = true;
            failedPoint++;
          }

          if (point.status === OrderStatus.Completed) {
            successPoint++;
          }
        }
      });

      payload.stop_point = path.length - 1;
      payload.success_point = successPoint;

      if (failedPoint === payload.stop_point) {
        // payload.status = OrderStatus.Failed;
        payload.fail_time = json.complete_time;
      }
    }
  }
}

function calculateStopPointAndSuccessPoint(
  payload: OrderTracking,
  json: OrderTracking
) {
  const path = json.path;
  if (path) {
    let failedPoint = 0;
    let successPoint = 0;
    path.forEach((point, index) => {
      if (index > 0) {
        if (point.status === OrderStatus.Failed) {
          payload.order_has_failed_stop_point = true;
          failedPoint++;
        }

        if (point.status === OrderStatus.Completed) {
          successPoint++;
        }
      }
    });

    payload.stop_point = path.length - 1;
    payload.success_point = successPoint;
    payload.failed_point = failedPoint;

    if (failedPoint === payload.stop_point) {
      // payload.status = OrderStatus.Failed;
      payload.fail_time = json.complete_time;
    }
  }
}

function getReturnTime(payload: OrderTracking, path: Path[]) {
  if (payload.order_has_failed_stop_point && path[0]) {
    payload.return_time = path[0].return_time;
    payload.por_info = path[0].por_info ?? '';
  }
}

function setOrderCompletedStatus(payload: OrderTracking, json: OrderTracking) {
  let isOrderCompleted = false;
  if (
    ((json.status === OrderStatus.Completed &&
      json.sub_status !== OrderStatus.InReturn) ||
      json.status === OrderStatus.Cancelled ||
      json.status === OrderStatus.Failed) &&
    (!payload.notify_package_return ||
      !payload.order_has_failed_stop_point ||
      (payload.return_time ?? 0) > 0)
  ) {
    isOrderCompleted = true;
  }
  payload.is_order_completed = isOrderCompleted;
}
