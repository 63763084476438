import {
  type AxiosRequestConfig,
  httpClient,
  apiRoutes,
} from '@ahm/api-wrappers-core';

export type GetUserInfoResponse = {
  _id: string;
  email: string;
  status: string;
  name: string;
  facebook_id: string;
  facebook_token: string;
  create_time: number;
};

export const getUserInfoFn = async (config: AxiosRequestConfig = {}) => {
  const response = await httpClient.get<GetUserInfoResponse>(
    apiRoutes.ahafood.get_info,
    config
  );

  return response?.data;
};
