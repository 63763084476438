import { apiRoutes } from '../../api-routes';
import { publicHttpClient } from '../../util';
import type { LngLat } from '../place';

export type ScreenConfigResponse = {
  city_id: string;
  configs: {
    banner: ScreenConfig[];
    banner_profile_menu: ScreenConfig[];
    explore_more: ScreenConfig[];
    featured_banner: ScreenConfig[];
    service_list: ScreenConfig[];
  };
};

export type ScreenConfig = {
  background_image_url?: string;
  icon_image_url?: string;
  enable: boolean;
  deep_link?: string;
  order: number;
  title_vi_vn: string;
  title_en_us: string;
  description_vi_vn: string;
  description_en_us: string;
  button_vi_vn: string;
  button_en_us: string;
  type: string;
  screen: string;
  color: string;
  platform: string[];
  device_type: ScreenConfigDeviceType[];
  delivery_type: string[];
  link?: string;
  badge?: string;
};

export enum ScreenConfigDeviceType {
  Android = 'android',
  Ios = 'ios',
  WAP = 'wap',
  Web = 'web',
}

export enum ScreenConfigPlatform {
  Wap = 'WAP',
  Web = 'WEB',
  Embed = 'EMBED',
  Ios = 'IOS',
  Android = 'ANDROID',
}

export const getScreenConfigsFn = async (params: LngLat) => {
  const response = await publicHttpClient.get<ScreenConfigResponse>(
    apiRoutes.master_data.screen_configs,
    {
      params,
    }
  );

  return response.data;
};
