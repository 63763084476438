import type { DefaultQueryError, TrackingPortalUser } from '../..';
import { apiRoutes } from '../../../api-routes';
import { baseUrl } from '../../../constants';
import { publicHttpClient } from '../../../util';

export type Session = {
  session_id: string;
  user?: TrackingPortalUser;
};

export const getSessionFn = async () => {
  try {
    const response = await publicHttpClient.get<Session | null>(
      baseUrl + apiRoutes.auth.internal.session
    );
    return response.data;
  } catch (error) {
    const errorData = (error as DefaultQueryError).response?.data;
    console.log('getSessionFn error', errorData);
    return null;
  }
};
