import { apiRoutes } from '../../../api-routes';
import { accountsUrl } from '../../../constants';
import { publicHttpClient } from '../../../util';
import type { ApiDataStatus } from '../../types';

export const authLogoutFn = async () => {
  const response = await publicHttpClient.post<{ status: ApiDataStatus }>(
    accountsUrl + apiRoutes.auth.internal.logout,
    {}
  );

  return response.data;
};
