import { apiRoutes } from '../../api-routes';
import { publicHttpClient } from '../../util/http-client';

type User = {
  id: string;
  name: string;
  email: string;
};

export const fbGetUserDataFn = async (FBtoken: string) => {
  const response = await publicHttpClient.get<User>(
    apiRoutes.ahafood.append_page(FBtoken)
  );

  return response.data;
};
