import type { DeliveryType } from '../../constants';
import type { EstimateIntercityOrderResponse } from '../intercity';
import type { PaymentMethodCode } from '../payment';

export type Service = {
  _id: string;
  accept_interval?: number;
  advance_broadcast_distance?: number;
  alternative_vehicles?: string[];
  auto_assign: boolean;
  auto_assign_distance?: number;
  city_id: string;
  cod: number;
  commission_type: string;
  commission_value: number;
  currency: string;
  default_vehicles?: string[];
  delivery_type: DeliveryType;
  description_en_us?: string;
  description_vi_vn?: string;
  distance_fee: string;
  enable: boolean;
  fee_description_en_us: string;
  fee_description_vi_vn?: string;
  first_order_cod: number;
  group_id?: string;
  group_name_en_us?: string;
  group_name_vi_vn?: string;
  icon_url: string;
  idle_timeout: number;
  is_saas: boolean;
  is_select_vehicle_allowed: boolean;
  is_tip_allowed: boolean;
  map_icon_3d: MapIcon3D | null;
  map_icon_url: string;
  max_cod: number;
  max_cod_per_stop_point?: number;
  max_concurrent_orders: number;
  max_distance: number;
  max_idle_until?: number;
  max_stop_points: number;
  min_stop_points: number;
  name: string;
  name_en_us: string;
  name_vi_vn: string;
  opening_hours: string;
  parcel_info?: ParcelInfo;
  parent_id: string;
  partner_distance_fee: string;
  pool_timeout_commission_value: number;
  requests?: SpecialRequest[];
  require_request: boolean;
  require_to: boolean;
  stop_fee: number;
  supplier_description?: string;
  timeout: number;
  tooltips: any[] | null;
  truck_ban?: TruckBan;
  vat_rate?: number;
  vehicle_info?: VehicleInfo;
  order?: number;
  partner?: boolean;
  complete_interval?: number;
  rent_order_config?: RentOrderConfig;
  service_hours?: Record<string, ServiceHour[]>;
  accept_message?: string;
  badge?: ServiceBadge;
  is_recommend?: boolean;
  enable_platform?: string[];

  payment_method: {
    payment: PaymentMethodCode;
  }[];
};

export type ParcelInfo = {
  max_height: {
    title_en_us?: string;
    title_vi_vn?: string;
    options?: ParcelInfoOption[];
  };
  max_length: {
    title_en_us?: string;
    title_vi_vn?: string;
    options?: ParcelInfoOption[];
  };
};

export type ParcelInfoOption = {
  description_en_us: string;
  description_vi_vn: string;
  from: number;
  to: number;
};

export type MapIcon3D = {
  sprite_count: number;
  sprite_size: number;
  url: string;
};

export type RentOrderConfig = {
  driver_checkout_mins: number;
  enable_contact_mins?: number;
  enable_extend_mins?: number;
  enable_map_mins?: number;
  end_worktime?: number;
  extend_time?: boolean;
  hide_cancel_mins?: number;
  interval_step_hours: number;
  label: string[];
  max_extent_mins?: number;
  max_rent_hours: number;
  min_extend_mins?: number;
  min_rent_hours: number;
};

export type SpecialRequest = {
  _id: string;
  applied_stp_num?: number;
  benefit_url?: string;
  category?: string;
  cod_commission?: CodCommission;
  delivery_option?: boolean;
  description_en_us?: string;
  description_url?: string;
  description_vi_vn?: string;
  description?: string;
  device_types: DeviceType[];
  enable: boolean;
  group_id?: string;
  icon_url: string;
  img_url: string;
  item_price?: number;
  max_distance?: number;
  max_input?: number;
  max_item_value?: number;
  max_pod?: number;
  max_cod_per_stop_point?: number;
  min_input?: number;
  name_en_us: string;
  name_vi_vn: string;
  name: string;
  no_commission?: boolean;
  no_promotion: boolean;
  no_supplier_payout: boolean;
  none_cod_price_blocks: Record<string, number> | null;
  options?: SpecialRequestOption[];
  order: number;
  original_price: number;
  price_blocks?: SpecialRequestPriceBlock[];
  price: number;
  required_item_value?: boolean;
  service_id: string;
  sms_template_v3?: string;
  subtype?: string;
  supplier_description_url?: string;
  support_remarks?: boolean;
  tier_list?: SpecialRequestTierList[];
  transfer_cod: boolean;
  type: SpecialRequestType;
  uniform_verify?: boolean;
  uniform_verify_message_en_us?: string;
  uniform_verify_message_vi_vn?: string;
};

export type CodCommission = {
  none_commission_limit: number;
  tiers: any[];
  value: number;
};

export enum DeviceType {
  Android = 'android',
  Ios = 'ios',
  Web = 'web',
}

export type SpecialRequestOption = {
  code: string;
  name_en_us: string;
  name_vi_vn: string;
  selectable: boolean;
  need_user_input?: boolean;
};

export type SpecialRequestPriceBlock = {
  from: number;
  price: number;
  to: number;
};

export type SpecialRequestTierList = {
  code: string;
  description_en_us: string;
  description_vi_vn: string;
  img_url: string;
  name_en_us: string;
  name_vi_vn: string;
  price: number;
  price_description_en_us: string;
  price_description_vi_vn: string;
  selectable: boolean;
};

export enum SpecialRequestType {
  Boolean = 'BOOLEAN',
  CodInsurance = 'COD_INSURANCE',
  Insurance = 'INSURANCE',
  CommissionDistancePercentage = 'COMMISSION_DISTANCE_PERCENTAGE',
  CommissionFlat = 'COMMISSION_FLAT',
  CommissionPercentage = 'COMMISSION_PERCENTAGE',
  Item = 'ITEM',
  PerUnit = 'PER_UNIT',
  Tier = 'TIER',
}
export type ItemTrend = {
  category: string;
  code: string;
  group: string;
  is_trending: boolean;
  keyword: string;
};
export type ItemTrends = ItemTrend[];
export type ParamsSuggestionItemTrend = {
  query: string;
};

export type SpecialRequestEstimateData = {
  //
};

export type ServiceHour = {
  //
};

export type TruckBan = {
  ban_districts: BanDistrict[];
  ban_hours: BanHour[];
  enable: boolean;
  recommend_service?: string;
};

export type BanDistrict = {
  _id: number;
  city_id: string;
  hasc_2: string;
  name: string;
  name_2: string;
  tick: boolean;
  idx_nWrXb?: number;
  spc_nWrXb?: number;
  idx_VvMpv?: number;
  spc_VvMpv?: number;
};

export type BanHour = {
  from: number;
  to: number;
};

export type VehicleInfo = {
  box_volume?: number;
  height?: number;
  image?: string;
  length?: number;
  short_description_en?: string;
  short_description_vi?: string;
  short_intro_en?: string;
  short_intro_vi?: string;
  weight?: number;
  width?: number;
  description_en_us?: string;
  description_vi_vn?: string;
  image_url?: string;
  images?: string[];
  fee_description?: string;
  cod_description?: string;
  truck_ban_description?: string;
};

export type ServiceBadge = {
  background_color: string;
  description_en_us: string;
  description_vi_vn: string;
  text_color: string;
};

export type ServiceWithEstimate = Service & {
  estimate_data?: EstimateIntercityOrderResponse;
};
