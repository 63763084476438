import { apiRoutes } from '../../api-routes';
import { type AxiosRequestConfig, publicHttpClient } from '../../util';
import type { TrackingPortalOrder } from './type';

export type GetListTrackingPortalOrderParams = Partial<{
  order_ids: string;
  tracking_numbers: string;
  from_time: number;
  to_time: number;
  status: string;
  time: string;
  page: number;
  size: number;
}>;

export async function getListTrackingPortalOrderFn(
  params: GetListTrackingPortalOrderParams,
  config: Omit<AxiosRequestConfig, 'params'> = {}
) {
  const response = await publicHttpClient.get<{
    data: TrackingPortalOrder[];
    total: number;
  }>(apiRoutes.tracking.orderList, {
    params,
    ...config,
  });

  return response.data;
}
