import { publicHttpClient } from '../../util/http-client';

export type PlaceholderUser = {
  id: number;
  name: string;
  username: string;
  email: string;
  address: {
    street: string;
    suite: string;
    city: string;
    zipcode: string;
    geo: {
      lat: string;
      lng: string;
    };
  };
  phone: string;
  website: string;
  company: {
    name: string;
    catchPhrase: string;
    bs: string;
  };
};

export const getExampleUsersFn = async () => {
  const response = await publicHttpClient.get<PlaceholderUser[]>(
    'https://jsonplaceholder.typicode.com/users'
  );
  return response.data;
};
