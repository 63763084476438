import { apiRoutes } from '../../api-routes';
import { publicHttpClient } from '../../util/http-client';
import type { User } from './types';

export interface ActivateRequestBody {
  activation_code: string;
  ahamove_sms_auth: boolean;
  country_code: string;
  fcm: boolean;
  imei: string;
  mobile: string;
  type: string;
}

export interface ActivateResponse {
  token: string;
  refresh_token: string;
  firebase_token: string;
  user: User;
}

export const activateUserFn = async (body: ActivateRequestBody) => {
  const response = await publicHttpClient.post<ActivateResponse>(
    apiRoutes.auth.activate,
    body
  );
  return response.data;
};
