import { apiRoutes } from '../../api-routes';
import { httpClient, type AxiosRequestConfig } from '../../util/http-client';
import type { IntercityOrder } from '../intercity/types';

export const getIntercityOrderDetailFn = async (
  orderId: string,
  config: AxiosRequestConfig = {}
) => {
  const response = await httpClient.get<IntercityOrder>(
    apiRoutes.intercity.details(orderId),
    {
      ...config,
    }
  );

  return response.data;
};
