import { apiRoutes } from '../../api-routes';
import { httpClient } from '../../util';
import type { IntercityPath, ItemDescription } from '../place';
import type { IntercityPackageDetail, IntercityServiceRequest } from '../intercity/types';


export interface CreateNewOrderRequestBody {
  service_id: string;
  path: IntercityPath[];
  requests: IntercityServiceRequest[];
  payment_method: string;
  total_pay?: number;
  order_time?: number;
  promo_code?: string;
  remarks?: string;
  admin_note?: string;
  parent_category?: string;
  route_optimized?: boolean;
  need_confirm?: boolean;
  idle_until?: number;
  items?: ItemDescription[];
  store_id?: number;
  suggest_promotion?: boolean;
  package_detail?: IntercityPackageDetail[];
}
export interface CreateNewOrderResponse {
  order_id: string;
}

export const createNewOrderFn = async (
  body: CreateNewOrderRequestBody
) => {
  const response = await httpClient.post<CreateNewOrderResponse>(
    apiRoutes.order.create,
    body
  );

  return response.data;
};
