import { apiRoutes } from '../../api-routes';
import { publicHttpClient } from '../../util/http-client';
import type { User } from './types';

export type RefreshTokenParams = Pick<RefreshTokenResponse, 'refresh_token'> & {
  type: string;
  mobile: User['_id'];
};

export interface RefreshTokenResponse {
  refresh_token: string;
  token: string;
}

export const refreshAccessTokenFn = async (body: RefreshTokenParams) => {
  const response = await publicHttpClient.post<RefreshTokenResponse>(
    apiRoutes.user.refreshToken,
    body
  );
  return response.data;
};
