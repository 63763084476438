import { apiRoutes } from '../../api-routes';
import { type AxiosRequestConfig, publicHttpClient } from '../../util';
import type { GetListTrackingPortalOrderParams } from './get-list-tracking-portal-order-fn';

export async function getTrackingPortalOrdersCountingFn(
  params: GetListTrackingPortalOrderParams,
  config: Omit<AxiosRequestConfig, 'params'> = {}
) {
  const response = await publicHttpClient.get<{
    total: number;
  }>(apiRoutes.tracking.countOrders, {
    params,
    ...config,
  });

  return response.data;
}
