import { apiRoutes } from '../../api-routes';
import { type AxiosRequestConfig, httpClient } from '../../util';
import type { EstimateNewOrderRequestBody } from '../intercity';
import type { IntercityOrderRequest } from '../intercity/types';
import type { IntercityPath } from '../place';
import type { ApiError } from '../types';

export interface EstimateNewOrderResponse {
  service_id: string;
  error?: ApiError;
  data?: EstimateNewOrderData;
  promo_error_message?: string;
}

export type EstimateNewOrderData = {
  distance: number;
  distance_fee: number;
  stop_fee: number;
  weight_fee: number;
  request_fee: number;
  discount: number;
  total_cod?: number;
  total_fee: number;
  total_pay: number;
  total_price: number;
  vat_fee: number;
  cod_commission_fee: number;
  online_pay: number;
  voucher_discount: number;
  surge_rate: number;
  special_request_price: number;
  subtotal_price: number;
  vat: number;
  distance_price: number;
  stoppoint_price: number;
  weight_price: number;
  cod_commission_price: number;
  total_balance: number;
  requests: IntercityOrderRequest[];
  payment_method: string;
  partner_id: string;
  promo_error_message?: string;
};

export const estimateNewOrderFn = async (
  body: EstimateNewOrderRequestBody,
  config: AxiosRequestConfig = {}
) => {
  const response = await httpClient.post<EstimateNewOrderResponse[]>(
    apiRoutes.order.estimate,
    body,
    config
  );

  return response.data;
};
