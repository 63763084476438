import { apiRoutes } from '../../api-routes';
import { publicHttpClient } from '../../util/http-client';
import type { Rating } from './type';

export type ProofOfWorkRatingParams = {
  reason_type: string;
  user_type: string;
  service_id: string;
  group_star: number;
  delivery_type: string;
};

export const getProofOfWork = async (params: ProofOfWorkRatingParams) => {
  const response = await publicHttpClient.get<Rating[]>(
    apiRoutes.master_data.pow,
    {
      params,
    }
  );

  return response.data;
};
