import { apiRoutes } from '../../api-routes';
import { publicHttpClient } from '../../util/http-client';

export type SupplierLocationParams = {
  order_id: string;
};

export type SupplierLocation = {
  lat: number;
  lng: number;
  track_time: number;
  status: string;
  track_time_str: Date;
};

export const getSupplierLocationsFn = async (
  params: SupplierLocationParams
) => {
  const response = await publicHttpClient.get<SupplierLocation[]>(
    apiRoutes.supplier.locations,
    {
      params,
    }
  );

  return response.data;
};
