import { apiRoutes } from '../../api-routes';
import { httpClient } from '../../util';
import type { SavedPlace } from './types';

export interface SavedPlaceParams extends Omit<SavedPlace, 'location'> {
  location: number[];
}

export const createUserPlaceFn = async (body: SavedPlaceParams) => {
  const response = await httpClient.post<SavedPlace>(
    apiRoutes.place.savedPlaces,
    body
  );
  return response.data;
};
