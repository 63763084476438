import { apiRoutes } from '../../api-routes';
import {
  type AxiosRequestConfig,
  epHttpClient,
  type ApiConfig,
} from '../../util';
import type { Address, Feature } from './types';
import { formatFeatureToAddress } from './utils';

export interface AutocompleteData {
  features: Feature[];
  source_list?: string[];
  type?: string;
}

export type AutocompleteParams = {
  input: string;
  lat?: number;
  lng?: number;
  mode?: string;
};

type Params = {
  text: string;
  'focus.point.lat'?: number;
  'focus.point.lng'?: number;
};

export const getAutocompleteFn = async (
  apiConfig: ApiConfig,
  requestParams: AutocompleteParams,
  config: AxiosRequestConfig = {}
) => {
  const { mode, input: text, lat, lng } = requestParams;
  let url = apiRoutes.place.autocomplete;

  if (mode === 'google') {
    url = apiRoutes.place.autocomplete_google;
  }

  const params: Params = {
    text,
  };

  if (lat && lng) {
    params['focus.point.lat'] = lat;
    params['focus.point.lng'] = lng;
  }

  const response = await epHttpClient(apiConfig).get<AutocompleteData>(url, {
    params,
    ...config,
  });

  const data = response.data;

  const predictions: Address[] = [];
  if (data?.features) {
    data.features.forEach((feature) => {
      if (Array.isArray(feature)) {
        feature.forEach((item) => {
          // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
          const address = formatFeatureToAddress(item);
          if (address) predictions.push(address);
        });
      } else {
        const address = formatFeatureToAddress(feature);
        if (address) predictions.push(address);
      }
    });
  }
  return predictions;
};
