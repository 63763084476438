import { apiRoutes } from '../../api-routes';
import { publicHttpClient } from '../../util/http-client';
import type { LoginResponse } from './types';

export interface LoginRequestBody {
  ahamove_sms_auth?: boolean;
  country_code?: string;
  mobile: string;
  resend?: boolean;
  is_saas?: boolean;
  login_type?: string;
}

export const loginFn = async (body: LoginRequestBody) => {
  const params = {
    ...body,
    ahamove_sms_auth: true,
    country_code: 'VN',
  };
  const response = await publicHttpClient.post<LoginResponse>(
    apiRoutes.auth.login,
    params
  );
  return response.data;
};
