import { apiRoutes } from '../../api-routes';
import { httpClient } from '../../util';
import type { RoEstimateOrderRequest, RoEstimateOrderResponse } from './type';

export interface RoEstimateOrderParams {
  body: RoEstimateOrderRequest;
  lang: string;
}

export const estimateOrderFn = async ({ body }: RoEstimateOrderParams) => {
  const response = await httpClient.post<RoEstimateOrderResponse>(
    `${apiRoutes.order.estimate}`,
    body
  );
  return response.data;
};
