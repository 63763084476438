import { apiRoutes } from '../../../api-routes';
import { accountsUrl } from '../../../constants';
import { publicHttpClient } from '../../../util';
import type { RegisterRequestBody } from '../register-fn';

export const getAuthChecksumRegisterFn = async (
  body: Pick<RegisterRequestBody, 'mobile' | 'email'> & { time: number }
) => {
  const response = await publicHttpClient.post<{ hash: string }>(
    accountsUrl + apiRoutes.auth.internal.authChecksum,
    body,
    {
      withCredentials: true,
    }
  );

  return response.data;
};
