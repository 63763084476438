export type PaymentMethodParams = Partial<{
  enable_methods: string;
  amount: number;
}>;
export type PaymentMethodResponse = {
  data: PaymentMethod[];
  status: string;
  message: string;
};

export type TransactionResponse = {
  status: string;
  amount: number;
  description: string;
  qr_code_url: string;
  transaction_id: string;
};

export type PaymentMethodGroupType = 'cash' | 'cash_less';

export enum PaymentMethodCode {
  Cash = 'CASH',
  CashByRecipient = 'CASH_BY_RECIPIENT',
  Balance = 'BALANCE',
  Momo = 'MOMO',
  Zalopay = 'ZALOPAY',
  // Smartpay = 'SMARTPAY', // Note: This line was commented out in the original enum.
  Vnpay = 'VNPAY',
  Viettelpay = 'VIETTELPAY',
  Shopeepay = 'SHOPEEPAY',
  BankTransfer = 'BANKTRANSFER',
  VpbankPrepaid = 'VPBANK_PREPAID',
  Vpbank = 'VPBANK',
  Card = 'CARD',
  BusinessAccount = 'BUSINESS_ACCOUNT',
}

export const PAYMENT_METHODS = {
  [PaymentMethodCode.BusinessAccount]: {
    code: PaymentMethodCode.BusinessAccount.toLowerCase(),
    short_name_vi_vn: 'Tài khoản doanh nghiệp',
    short_name_en_us: 'Business Account',
    icon_url:
      'https://vncdn.ahamove.com/ahamoveimg/payment_icon/business_account.png',
    name_vi_vn: 'Tài khoản doanh nghiệp',
    name_en_us: 'Business Account',
    method_type: 'business_account',
    method_id: 'CDRJ',
    group_type: 'cash_less',
  },
  [PaymentMethodCode.Balance]: {
    code: PaymentMethodCode.Balance.toLowerCase(),
    icon_url:
      'https://ahamoveimg.s3-ap-southeast-1.amazonaws.com/Logo+Partners/Logo_AhaBalance.png',
    name_vi_vn: 'Tài khoản',
    name_en_us: 'Balance',
    method_type: 'balance',
    method_id: 'KNDG',
    group_type: 'cash_less',
  },
  [PaymentMethodCode.Zalopay]: {
    code: PaymentMethodCode.Zalopay.toLowerCase(),
    icon_url:
      'https://ahamoveimg.s3-ap-southeast-1.amazonaws.com/Logo+Partners/Logo_ZaloPay.png',
    name_vi_vn: 'ZaloPay',
    name_en_us: 'ZaloPay',
    method_type: 'e_wallet',
    method_id: 'CWON',
    group_type: 'cash_less',
  },
  [PaymentMethodCode.Momo]: {
    code: PaymentMethodCode.Momo.toLowerCase(),
    icon_url:
      'https://ahamoveimg.s3-ap-southeast-1.amazonaws.com/Logo+Partners/Logo_MoMo.png',
    name_vi_vn: 'MOMO',
    name_en_us: 'MOMO',
    method_type: 'e_wallet',
    method_id: 'FKIC',
    group_type: 'cash_less',
  },
  [PaymentMethodCode.Viettelpay]: {
    code: PaymentMethodCode.Viettelpay.toLowerCase(),
    short_name_vi_vn: 'ViettelPay1',
    short_name_en_us: 'ViettelPay2',
    icon_url:
      'https://ahamoveimg.s3-ap-southeast-1.amazonaws.com/Logo+Partners/Logo_ViettelPay.png',
    name_vi_vn: 'ViettelPay',
    name_en_us: 'ViettelPay',
    method_type: 'e_wallet',
    method_id: 'G9TR',
    group_type: 'cash_less',
  },
  [PaymentMethodCode.Shopeepay]: {
    code: PaymentMethodCode.Shopeepay.toLowerCase(),
    icon_url:
      'https://ahamoveimg.s3.ap-southeast-1.amazonaws.com/Logo+Partners/Logo_ShopeePay.png',
    name_vi_vn: 'ShopeePay',
    name_en_us: 'ShopeePay',
    method_type: 'e_wallet',
    method_id: 'AEYU',
    group_type: 'cash_less',
  },
  [PaymentMethodCode.Cash]: {
    code: PaymentMethodCode.Cash.toLowerCase(),
    short_name_vi_vn: 'Người gửi',
    short_name_en_us: 'Sender',
    icon_url:
      'https://ahamoveimg.s3-ap-southeast-1.amazonaws.com/Logo+Partners/Cashbysender.png',
    name_vi_vn: 'Người gửi trả phí giao hàng',
    name_en_us: 'Delivery fee is paid by sender',
    method_type: 'cash',
    method_id: '230Y',
    group_type: 'cash',
  },
  [PaymentMethodCode.CashByRecipient]: {
    code: PaymentMethodCode.CashByRecipient.toLowerCase(),
    short_name_vi_vn: 'Người nhận',
    short_name_en_us: 'Recipient',
    icon_url:
      'https://ahamoveimg.s3-ap-southeast-1.amazonaws.com/Logo+Partners/Cashbyrep.png',
    name_vi_vn: 'Người nhận trả phí giao hàng',
    name_en_us: 'Delivery fee is paid by recipient',
    method_type: 'cash_by_recipient',
    method_id: 'NQKX',
    group_type: 'cash',
  },
};

export type PaymentMethod = {
  code: string;
  group_type: string;
  method_id: string;
  method_type: string;
  extra_data?: PaymentMethodExtraData;
  enable: boolean;
  is_default: boolean;
  name_vi_vn?: string;
  name_en_us?: string;
  icon_url: string;
  is_corporate_method: boolean;
  is_support_tokenize: boolean;
  error_message_vi_vn: string;
  error_message_en_us: string;
  button_color: string;
  disabled_for_services?: string[];
  description_vi_vn?: string;
  description_en_us?: string;
  short_name_vi_vn?: string;
  short_name_en_us?: string;
};

export type PaymentMethodExtraData = {
  card_type?: string;
  card_number?: string;
  is_linked?: boolean;
  ref_id?: string;
};

export enum PaymentType {
  Subscription = 'subscription',
  TopUp = 'top-up',
  Order = 'order',
  Reimbursement = 'reimbursement',
  Pod = 'pod',
  AhaRent = 'aharent',
}

export interface PaymentDetails {
  icon_url: string;
  method_id: string;
  method_title: string;
  method_type: string;
  method_code: string;
}
