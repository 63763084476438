import axios, {
  type AxiosError,
  type AxiosInstance,
  type AxiosRequestConfig,
  type AxiosRequestHeaders,
  type AxiosResponse,
  type InternalAxiosRequestConfig,
} from 'axios';
import { API_FETCH_TIMEOUT, apiUrl } from '../constants';
import { installAxiosInterceptors } from './jwt-handler';

export const publicHttpClient = axios.create({
  baseURL: apiUrl,
  timeout: API_FETCH_TIMEOUT * 1000,
  headers: {
    'Content-Type': 'application/json',
  },
});

export type ApiConfig = {
  apiURL: string;
  apiKey: string;
};

export const epHttpClient = ({ apiURL: baseURL, apiKey: apikey }: ApiConfig) =>
  axios.create({
    baseURL,
    timeout: API_FETCH_TIMEOUT * 1000,
    headers: {
      'Content-Type': 'application/json',
      apikey,
    },
  });

export type HttpClientInstance = AxiosInstance & {
  CancelToken: typeof axios.CancelToken;
  isCancel: typeof axios.isCancel;
};

const httpClient = axios.create({
  baseURL: apiUrl,
  timeout: API_FETCH_TIMEOUT * 1000,
}) as HttpClientInstance;

httpClient.defaults.headers.common['Content-Type'] = 'application/json';

// eslint-disable-next-line import/no-named-as-default-member
httpClient.CancelToken = axios.CancelToken;
httpClient.isCancel = axios.isCancel;

export type HttpClientConfig = InternalAxiosRequestConfig & {
  token_required?: boolean;
  rawConfig?: HttpClientConfig;
  stringify_data?: boolean;
  _retry?: boolean;
};

installAxiosInterceptors(httpClient);

export { httpClient };

export type {
  AxiosError,
  AxiosInstance,
  AxiosRequestConfig,
  AxiosRequestHeaders,
  AxiosResponse,
  InternalAxiosRequestConfig,
};
