import { apiRoutes } from '../../api-routes';
import { DeliveryType } from '../../constants';
import {
  publicHttpClient,
  type AxiosRequestConfig,
} from '../../util/http-client';
import type { LngLat } from '../place';
import type { Service } from './types';

export type ServiceTypesParams = LngLat &
  Partial<{
    city_id: string;
    type: string;
    partner: string; // get Saas service partner
    delivery_type: DeliveryType | DeliveryType[];
    user_mobile: string; // get services of this user_mobile
    category: string; // support filter for aha-rent
  }>;

export const getServiceTypesFn = async (
  params: ServiceTypesParams,
  config: AxiosRequestConfig = {}
) => {
  const response = await publicHttpClient.get<Service[]>(
    apiRoutes.service.serviceTypes,
    {
      params,
      ...config,
      headers: {
        ...(config.headers ?? {}),
        'accepts-version':
          params.delivery_type === DeliveryType.Nationwide ? 3 : 2,
      },
    }
  );
  return response.data;
};
