import { apiRoutes } from '../../api-routes';
import {
  type AxiosRequestConfig,
  publicHttpClient,
} from '../../util/http-client';
import type { OrderTracking } from './type';
import { orderTrackingFromJSON } from './utils';

export type GetOrderTrackingViaOrderIdParams = {
  order_id: OrderTracking['_id'];
  user_id: OrderTracking['user_id'];
};

export const getOrderTrackingViaOrderIdFn = async (
  params: GetOrderTrackingViaOrderIdParams,
  config: AxiosRequestConfig = {}
) => {
  const response = await publicHttpClient.get<OrderTracking>(
    apiRoutes.tracking.viaOrderId(params),
    {
      ...config,
    }
  );

  return orderTrackingFromJSON(response.data);
};
