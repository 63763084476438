import type { AxiosRequestConfig } from 'axios';
import { apiRoutes } from '../../api-routes';
import { httpClient } from '../../util';
import type { RetryResponse } from './types';
export type retryParams = {
  status: string;
  tracking_number?: string;
  order_id?: string;
  partner_id: string;
  sub_status?: string;
};
export const retryCallbackFn = async (
  { status, sub_status, tracking_number, order_id, partner_id }: retryParams,
  config?: AxiosRequestConfig
) => {
  const response = await httpClient.post<RetryResponse>(
    apiRoutes.testing_tool.retry_callback(),
    {
      status,
      tracking_number,
      order_id,
      partner_id,
      sub_status,
    },
    {
      ...config,
    }
  );
  return response.data;
};
