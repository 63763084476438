import { type JWTPayload, SignJWT, jwtVerify } from 'jose';

const secretKey = 'mBIyrjnuAw585MqUE0LVNLdwLj4J6QKoUnoiw9ndAmI=';
const key = new TextEncoder().encode(secretKey);

export async function jwtEncrypt(payload: JWTPayload) {
  return await new SignJWT(payload)
    .setProtectedHeader({ alg: 'HS256' })
    .setIssuedAt()
    .setExpirationTime('10 sec from now')
    .sign(key);
}

export async function jwtDecrypt(token: string) {
  const { payload } = await jwtVerify(token, key, {
    algorithms: ['HS256'],
  });

  return payload;
}
