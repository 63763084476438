import { apiRoutes } from '../../api-routes';
import { httpClient, type AxiosRequestConfig } from '../../util/http-client';
import type { OrderDetail } from './types';

export const getOrderDetailFn = async (
  orderId: string,
  config: AxiosRequestConfig = {}
) => {
  const response = await httpClient.get<OrderDetail>(
    apiRoutes.order.detail(orderId),
    {
      ...config,
    }
  );

  return response.data;
};
