import { apiRoutes } from '../../api-routes';
import {
  type AxiosRequestConfig,
  publicHttpClient,
} from '../../util/http-client';
import type { Service } from './types';

export interface GetPublicServiceDetailParams {
  service_id: Service['_id'];
  city_id?: Service['city_id'];
}

export const getPublicServiceDetailFn = async (
  { service_id: serviceId, ...params }: GetPublicServiceDetailParams,
  config: AxiosRequestConfig = {}
) => {
  const response = await publicHttpClient.get<Service>(
    apiRoutes.service.detailById(serviceId),
    {
      params,
      ...config,
    }
  );

  return response.data;
};
