import { apiRoutes } from '../../api-routes';
import { publicHttpClient, type AxiosRequestConfig } from '../../util';
import type { OrderTracking } from './type';

export const getTrackingPortalOrderFn = async (
  orderId: string,
  config: Omit<AxiosRequestConfig, 'params'> = {}
) => {
  const response = await publicHttpClient.get<OrderTracking>(
    apiRoutes.tracking.orderDetail(orderId),
    {
      ...config,
    }
  );

  return response.data;
};
