import type { DefaultQueryError, User } from '../..';
import { apiRoutes } from '../../../api-routes';
import { accountsUrl } from '../../../constants';
import { publicHttpClient } from '../../../util';

export type AuthSession = {
  token: string;
  refresh_token?: string;
  user?: User;
};

export const getAuthSessionFn = async () => {
  try {
    const response = await publicHttpClient.get<AuthSession | null>(
      accountsUrl + apiRoutes.auth.internal.authSession,
      {
        withCredentials: true,
      }
    );
    return response.data;
  } catch (error) {
    const errorData = (error as DefaultQueryError).response?.data;
    console.log('getAuthSessionFn error', errorData);
    return null;
  }
};
