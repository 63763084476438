import type {
  CreateIntercityOrderRequestBody,
  FromLocation,
  Service,
} from '..';
import type { PaymentDetails } from '../payment';
import type { IntercityPath } from '../place';

export interface Province {
  _id: string;
  name_vn: string;
  level: LocationLevel.Province;
}

export interface District {
  _id: string;
  name_vn: string;
  level: LocationLevel.District;
  region_id: string;
}

export interface Ward {
  _id: string;
  name_vn: string;
  level: LocationLevel.Ward;
  region_id: string;
}

export enum LocationLevel {
  Province = 2,
  District = 3,
  Ward = 4,
}

export type IntercityServiceRequest = {
  _id: string; // Service ID
  requests: IntercityOrderRequest[] | []; // List of special request object or []
};

export type IntercityOrderRequest = {
  _id: string; // ID of the special request (e.g SGN-BIKE-TIPS)
  price?: number; // Price of the special request
  num?: number; // Number of unit special request if it's PER_UNIT type
  tier_code?: string; // Code of selected tier if it's TIER type
  option_code?: string; // Code of selected option (e.g option in SGN-BIKE-FRAGILE)
  option_detail?: string; // Option detail of selected option
  hide_in_order_details?: boolean;
};

export type IntercityPackageDetail = {
  code?: string;
  name?: string;
  weight: number;
  height: number;
  width: number;
  length: number;
  quantity: number;
  billable_weight: number;
  volumetric_weight: number;
};

export type IntercityOrder = {
  _id: string;
  user_id: string;
  user_name: string;
  user_imei: string;
  status: string;
  delivery_type: string;
  from_location: FromLocation;
  path: IntercityPath[];
  service_id: string;
  service: Service;
  distance: number;
  order_time: number;
  payment_details: PaymentDetails;
  package_details: IntercityPackageDetail[];
  package_dimensions: IntercityPackageDimensions;
  distance_fee: number;
  stop_fee: number;
  weight_fee: number;
  request_fee: number;
  discount: number;
  total_fee: number;
  total_pay: number;
  vat_fee: number;
  cod_commission_fee: number;
  online_pay: number;
  user_parent_id: string;
  user_payment_id: string;
  cancel_time: number;
  cancel_code: string;
  cancel_comment: string;
  cancel_by: string;
  create_time: number;
  idle_until: number;
  payment_method: string;
  user_main_account: number;
  user_bonus_account: number;
  tpl_id: string;
  images: string[];
  nationwide_province_id?: string;
  nationwide_district_id?: string;
  nationwide_ward_id?: string;
  package_detail: IntercityPackageDetail[];
  promo_code?: string;
  package_images?: string[];
  remarks?: string;
};

export type IntercityPackageDimensions = {
  dim_factor: number;
  volumetric_weight: number;
  weight: number;
  billable_weight: number;
  length: number;
  width: number;
  height: number;
  unit: string;
  weight_unit: string;
};

export type IntercityPathInfo = Pick<
  IntercityPath,
  'item_description' | 'item_order_id' | 'item_name' | 'item_value'
>;

export type IntercityPackageForm = IntercityPathInfo &
  Pick<CreateIntercityOrderRequestBody, 'package_images' | 'package_detail'>;
