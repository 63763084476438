import { apiRoutes } from '../../../api-routes';
import { baseUrl } from '../../../constants';
import { type AxiosRequestConfig, publicHttpClient } from '../../../util';
import type { OrderTracking } from '../type';
import { orderTrackingFromJSON } from '../utils';

export const getInternalTrackingDetailFn = async (
  orderId: string,
  config: Omit<AxiosRequestConfig, 'params'> = {}
) => {
  const response = await publicHttpClient.get<OrderTracking>(
    baseUrl + apiRoutes.tracking.internal.orderDetail(orderId),
    {
      ...config,
      withCredentials: true,
    }
  );

  return orderTrackingFromJSON(response.data);
};
