import { apiRoutes } from '../../api-routes';
import {
  type AxiosRequestConfig,
  epHttpClient,
  type ApiConfig,
} from '../../util';
import type { FindAdmin, FindAdminRequest } from './types';

export const findAdminFn = async (
  apiConfig: ApiConfig,
  body: FindAdminRequest,
  config: AxiosRequestConfig = {}
) => {
  const response = await epHttpClient(apiConfig).post<FindAdmin[]>(
    apiRoutes.place.findAdmin,
    { ...body },
    { ...config }
  );

  return response.data;
};
