import { apiRoutes } from '../../api-routes';
import {
  type AxiosRequestConfig,
  publicHttpClient,
} from '../../util/http-client';
import type { OrderTracking } from '../tracking';

export type SupplierLocate = {
  lat: number;
  lng: number;
  order_status: string;
  testing: boolean;
  extra: SupplierLocateExtra;
  prev_lat: number;
  prev_lng: number;
  prev_extra: SupplierLocateExtra;
  sub_status: string;
};

export type SupplierLocateExtra = {
  _id: string;
  lat: number;
  lng: number;
  altitude: number;
  horizontal_accuracy: number;
  provider: string;
  speed: number;
  time: number;
  status: string;
  course: number;
};

export interface GetLocateSupplierParams {
  order_id: OrderTracking['_id'];
  user_id: OrderTracking['user_id'];
}

export const getLocateSupplierFn = async (
  params: GetLocateSupplierParams,
  config: AxiosRequestConfig = {}
) => {
  const response = await publicHttpClient.get<SupplierLocate>(
    apiRoutes.order.locateSupplier,
    {
      params,
      ...config,
    }
  );

  return response.data;
};
