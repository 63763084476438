import { apiRoutes } from '../../api-routes';
import { httpClient } from '../../util';
import type { PromotionsRes, QueryParamsPromotion } from './types';

export const getInfinitePromotionsFn = async (params: QueryParamsPromotion) => {
  const response = await httpClient.get<PromotionsRes>(
    apiRoutes.promotion.list_promotions,
    {
      params,
    }
  );
  return response.data;
};
