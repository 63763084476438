import { apiRoutes } from '../../api-routes';
import { publicHttpClient } from '../../util/http-client';
import type { ResetPasscodeRequest, ResetPasscodeResponse } from './types';

export const resetPasscodeFn = async (body: ResetPasscodeRequest) => {
  const response = await publicHttpClient.put<ResetPasscodeResponse>(
    apiRoutes.auth.resetPasscode,
    {
      ...body,
    }
  );
  return response.data;
};
