import { apiRoutes } from '../../api-routes';
import { httpClient } from '../../util';
import type { IntercityPath } from '../place';
import type { IntercityPackageDetail, IntercityServiceRequest } from '../intercity/types';

export interface CreateIntercityOrderRequestBody {
  path: IntercityPath[];
  service: IntercityServiceRequest;
  package_detail: IntercityPackageDetail[];
  payment_method_id: string;
  promo_code: string;
  total_price: number;
  images?: string[];
  package_images?: string[];
}
export interface CreateIntercityOrderResponse {
  order_id: string;
}

export const createIntercityOrderFn = async (
  body: CreateIntercityOrderRequestBody
) => {
  const response = await httpClient.post<CreateIntercityOrderResponse>(
    apiRoutes.intercity.create,
    body
  );

  return response.data;
};
