import { apiRoutes } from '../../../api-routes';
import { baseUrl } from '../../../constants';
import { type AxiosRequestConfig, publicHttpClient } from '../../../util';
import type { GetListTrackingPortalOrderParams } from '../get-list-tracking-portal-order-fn';

export async function getInternalTrackingOrdersCountingFn(
  params: GetListTrackingPortalOrderParams,
  config: Omit<AxiosRequestConfig, 'params'> = {}
) {
  const response = await publicHttpClient.get<{
    total: number;
  }>(baseUrl + apiRoutes.tracking.internal.countOrders, {
    params,
    ...config,
    withCredentials: true,
  });

  return response.data;
}
