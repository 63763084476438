import { apiRoutes } from '../../api-routes';
import { publicHttpClient } from '../../util';
import type { ApiDataStatus } from '../types';

export interface LoginTrackingPortalRequestBody {
  user_name: string;
  password: string;
}

export type TrackingPortalUser = {
  _id: string;
  phone: string;
  email: string;
  name: string;
  partner_id: string;
  create_time: number;
  account_status: string;
  tags?: string[];
  partner_logo_url?: string;
};

export interface LoginTrackingPortalResponse {
  data: TrackingPortalUser;
  status: ApiDataStatus;
}

export const loginTrackingPortalFn = async (
  body: LoginTrackingPortalRequestBody
) => {
  return await publicHttpClient.post<LoginTrackingPortalResponse>(
    apiRoutes.tracking.userLogin,
    body
  );
};
