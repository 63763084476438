import type { OrderStatus } from '../../constants';
import type { OrderRequest } from '../order';

export type LngLat = {
  lng: number;
  lat: number;
};

export interface Path {
  address: string; // Text address
  lat: number; // Latitude
  lng: number; // Longitude
  name?: string; // Name of receiver or sender
  mobile?: string; // Mobile phone of receiver or sender
  cod?: number; // COD amount in this stop point
  formatted_address?: string; // Formatted text address
  short_address?: string; // Short address text
  address_code?: string; // Address code
  remarks?: string; // A note for supplier
  images?: string[];
  first_label?: string;
  second_label?: string;
  types?: string[];
  item_value?: number;
  tracking_number?: string;
  level?: string;
  item_description?: string;
  item_descriptions?: ItemDescription[];
  apt_number?: string;
  building?: string;
  require_pop?: boolean;
  pop_type?: string;
  require_pod?: boolean;
  pod_type?: string;
  por_type?: string;
  por_info?: string;
  pod_info?: string;
  pof_info?: string;
  adr_source?: string;
  fb_conversation_id?: string;
  weight?: number;
  shipping_type?: string;
  partner?: string;
  point_type?: PointType;
  action_type?: PointType;
  requests?: OrderRequest[];
  return_time?: number;
  status?: OrderStatus;
  arrival_time?: number;
  complete_time?: number;
  fail_time?: number;
  fail_comment?: string;
  return_lat?: number;
  return_lng?: number;
  complete_lat?: number;
  complete_lng?: number;
  arrival_lat?: number;
  arrival_lng?: number;
  fail_lat?: number;
  fail_lng?: number;
  require_pof?: boolean;
  pof_type?: string;
  enable_upload_pof?: boolean;
  items?: PathOrderItem[];
  pod_types?: string[];
  cash_collection?: number;
  require_por?: boolean;
  pop_info?: string;
  limit_call?: boolean;
  proof_of_delivery?: ProofOfDelivery;
  redelivery_note?: RedeliveryNote;
  district_id?: string;
  province_id?: string;
  ward_id?: string;
  note?: string;
  inspection_policies?: ('ALLOW_TO_INSPECT_GOODS' | 'ALLOW_TO_TRY_GOODS')[];
}

export interface IntercityPath
  extends Omit<
    Path,
    | 'lat'
    | 'lng'
    | 'name'
    | 'mobile'
    | 'province_id'
    | 'district_id'
    | 'ward_id'
  > {
  lat?: number;
  lng?: number;
  address: string;
  mobile: string;
  name?: string;
  province_id?: string;
  district_id?: string;
  ward_id?: string;
  // name: string;
  // province_id: string;
  // district_id: string;
  // ward_id: string;
  inspection_policies: ('ALLOW_TO_INSPECT_GOODS' | 'ALLOW_TO_TRY_GOODS')[];

  item_description?: string;
  item_name?: string;
  item_order_id?: string; // tracking_number
  item_value?: number;
  nationwide_province_id?: string;
  nationwide_district_id?: string;
  nationwide_ward_id?: string;
}

export interface RedeliveryNote {
  note: string;
}

export type ProofOfDelivery = {
  document: string[];
  photo: string[];
};

export type PathOrderItem = {
  name: string;
  price: number;
  quantity: number | string;
  sku: string;
};

export interface ItemDescription {
  code: string;
  keyword: string;
  group: string;
}

export interface Address {
  address: string;
  description?: string;
  short_address?: string;
  adr_source?: string;
  first_label?: string;
  second_label?: string;
  types?: string[];
  geometry?: Geometry;
  lat?: number;
  lng?: number;
  contact_name?: string;
  contact_number?: string;
  place_id?: string;
  sessiontoken?: string;
  code?: string;
  address_detail?: string;
  name?: string;
  level?: string;
  remarks?: string;
}

export interface GeocodeReverseData {
  data: Feature;
  source: string;
}

export interface Feature {
  geometry: Geometry;
  properties: Properties;
  type: string;
}

export interface Geometry {
  coordinates: number[];
  type: string;
}

export interface Properties {
  accuracy: string;
  address: string;
  county: string;
  county_a: string;
  county_gid: string;
  distance: number;
  gid: string;
  id: string;
  label: string;
  layer: string;
  locality: string;
  locality_a: string;
  locality_gid: string;
  name: string;
  region: string;
  region_a: string;
  region_gid: string;
  source: string;
  source_id: string;
  housenumber?: string;
  street?: string;
  place_id?: string;
  contact_name?: string;
  contact_number?: string;
  sessiontoken?: string;
}

export type SavedPlace = {
  address?: string;
  address_detail?: string;
  code?: string;
  contact_name?: string;
  contact_number?: string;
  count?: number;
  created_at?: number;
  location?: {
    coordinates: number[];
    type: string;
  };
  name?: string;
  sort?: number;
  type?: string;
  updated_at?: number;
  user_id?: string;
  lat?: number;
  lng?: number;
};
export type RoPickupPoint = {
  pickup: {
    address: string;
    lat?: string;
    lng?: string;
  };
  name?: string;
  mobile?: string;
  place_detail?: string;
  point_type?: string;
  first_label?: string;
  second_label?: string;
  short_address?: string;
};
export type StopPoint = {
  address: string; // Text address
  lat: number; // Latitude
  lng: number; // Longitude
  name: string; // Name of receiver or sender
  mobile: string; // Mobile phone of receiver or sender
  cod?: number; // COD amount in this stop point
  formatted_address?: string; // Formatted text address
  short_address?: string; // Short address text
  address_code?: string; // Address code
  remarks?: string; // A note for supplier
  images?: string[];
  first_label?: string;
  second_label?: string;
  types?: string[];
  item_value?: number;
  tracking_number?: string;
  level?: string;
  item_description?: string;
  item_descriptions?: ItemDescription[];
  apt_number?: string;
  building?: string;
  require_pop?: boolean;
  pop_type?: string;
  require_pod?: boolean;
  pod_type?: string;
  adr_source?: string;
  fb_conversation_id?: string;
  weight?: number;
  items?: number;
  shipping_type?: string;
  partner?: string;
  point_type?: PointType;
  action_type?: PointType;
};

export enum PointType {
  Pickup = 'PICK UP',
  DropOff = 'DROP OFF',
}

export interface FindAdminRequest {
  point: {
    lattitude: number;
    longitude: number;
  };
}

export interface FindAdmin {
  _id: string;
  geometry: Geometry;
  name: string;
  name_vn: string;
  level: number;
  level_vn: string;
  centroid: LngLat;
  parent_ids?: string[];
}

export type BatchGeocodeReq = {
  addresses: {
    address: string;
  }[];
};

export interface BatchGeoCode {
  latitude: number;
  longitude: number;
  short_address: string;
  name: string;
  types: string[];
  provider: string;
  address: string;
  isCached: boolean;
  source: string;
}
