import type { AxiosRequestConfig } from 'axios';
import { apiRoutes } from '../../api-routes';
import { publicHttpClient } from '../../util';
import type { District, Province } from '../intercity/types';

export const getDistrictsFn = async (
  provinceId: Province['_id'],
  config: AxiosRequestConfig = {}
) => {
  const response = await publicHttpClient.get<{ data: District[] }>(
    apiRoutes.intercity.districts(provinceId),
    {
      ...config,
    }
  );

  return response.data.data;
};
