import { apiRoutes } from '../../api-routes';
import { httpClient } from '../../util';
import type { Promotion } from './types';

export const getPromotionDetailFn = async (id: string) => {
  const response = await httpClient.get<Promotion>(
    apiRoutes.promotion.promotion_detail(id)
  );
  return response.data;
};
