import type { AxiosRequestConfig } from 'axios';
import { apiRoutes } from '../../api-routes';
import { httpClient } from '../../util/http-client';

export type Tag = {
  // toLowerCase?(): unknown;
  tag: string;
};

export async function getTagsListFn(
  config: Omit<AxiosRequestConfig, 'params'> = {}
): Promise<{ data: Tag[] }> {
  const response = await httpClient.get<{
    data: Tag[];
  }>(apiRoutes.onwheel.tag_list, {
    ...config,
  });

  return { data: response.data.data };
}
