import { apiRoutes } from '../../api-routes';
import { publicHttpClient } from '../../util/http-client';
import type { VerifyOTPLogin, VerifyOTPLoginResponse } from './types';

export const verifyOTPLoginFn = async (body: VerifyOTPLogin) => {
  const response = await publicHttpClient.post<VerifyOTPLoginResponse>(
    apiRoutes.auth.verifyOtp,
    {
      ahamove_sms_auth: true,
      country_code: 'VN',
      ...body,
    }
  );
  return response.data;
};
