import { apiRoutes } from '../../api-routes';
import { publicHttpClient, type AxiosRequestConfig } from '../../util';

export type PayloadRating = {
  rated_by: string;
  client_id: string;
  rating: number;
  comment?: string;
  badge_list?: string[];
};

export const rateOrderPublicFn = async (
  orderId: string,
  payload: PayloadRating,
  config: Omit<AxiosRequestConfig, 'params'> = {}
) => {
  const response = await publicHttpClient.put<any>(
    apiRoutes.order.publicRate(orderId),
    payload,
    {
      ...config,
    }
  );

  return response.data;
};
