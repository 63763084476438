import { getCookie } from 'cookies-next';
import { createStore } from 'zustand/vanilla';
import type { AuthSession, User } from '../api';
import { logoutV1Fn } from '../api/auth/logout-fn';
import {
  COOKIE_NAME__ACCESS_TOKEN,
  COOKIE_NAME__REFRESH_TOKEN,
} from '../constants';
import {
  removeAccessToken,
  removeRefreshToken,
  storeAccessToken,
  storeRefreshToken,
} from './token';

export enum AuthStatus {
  Loading = 'loading',
  Unauthenticated = 'unauthenticated',
  Authenticated = 'authenticated',
}

export type AuthState = {
  session: AuthSession | null;
  status?: AuthStatus;
  isTokenExpired?: boolean;
};

export type AuthActions = {
  setSession: (session: AuthSession) => void;
  clearSession: (isTokenExpired: boolean) => void;
  logout: (isTokenExpired?: boolean) => void;
  updateUserSession: (user: User) => void;
};

export type AuthStore = AuthState & AuthActions;

export const defaultInitState: AuthState = {
  session: null,
};

export const authStore = createStore<AuthStore>()((set, get) => {
  const accessToken = getCookie(COOKIE_NAME__ACCESS_TOKEN);
  const refreshToken = getCookie(COOKIE_NAME__REFRESH_TOKEN);

  let session: AuthSession | null = null;

  if (accessToken) {
    session = {
      token: accessToken,
    };

    if (refreshToken) {
      session.refresh_token = refreshToken;
    }
  }

  return {
    session,
    status: session ? AuthStatus.Authenticated : AuthStatus.Unauthenticated,
    isTokenExpired: false,
    setSession: (session) => {
      storeAccessToken(session.token);
      if (session.refresh_token) {
        storeRefreshToken(session.refresh_token);
      }

      set({ session, status: AuthStatus.Authenticated, isTokenExpired: false });
    },
    clearSession: (isTokenExpired: boolean) => {
      set({
        session: null,
        status: AuthStatus.Unauthenticated,
        isTokenExpired,
      });

      removeAccessToken();
      removeRefreshToken();
    },
    logout: (isTokenExpired = false) => {
      const accessToken = getCookie(COOKIE_NAME__ACCESS_TOKEN);
      const refreshToken = getCookie(COOKIE_NAME__REFRESH_TOKEN);

      let session: AuthSession | null = null;

      if (accessToken) {
        session = {
          token: accessToken,
        };

        if (refreshToken) {
          session.refresh_token = refreshToken;
        }
      }

      try {
        const token = session?.token;
        if (!token) {
          return;
        }
        get().clearSession(isTokenExpired);
        logoutV1Fn(token)
          .then(() => {
            console.log('logout success');
          })
          .catch((error) => {
            console.log('error logout: ', error);
          });
      } catch (error) {
        console.log('error logout: ', error);
      }
    },
    updateUserSession: (user) => {
      const currentSession = get().session;
      if (currentSession && user) {
        set({
          session: {
            ...currentSession,
            user: {
              ...currentSession.user,
              ...user,
            },
          },
          status: AuthStatus.Authenticated,
          isTokenExpired: false,
        });
      }
    },
  };
});
