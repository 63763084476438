import { apiRoutes } from '../../api-routes';
import { httpClient } from '../../util';
import type { RoCreateOrderResponse, RoEstimateOrderRequest } from './type';

export interface RoCreateOrderParams {
  body: RoEstimateOrderRequest;
  lang: string;
}

export const createRoOrderFn = async ({ body }: RoCreateOrderParams) => {
  const response = await httpClient.post<RoCreateOrderResponse>(
    `${apiRoutes.order.createOrderRo}`,
    body
  );
  return response.data;
};
