import type { GetOrderTrackingViaOrderIdParams } from './api/tracking';

export const apiRoutes = {
  auth: {
    activate: '/api/v3/public/user/activate',
    login: '/api/v3/public/user/login',
    socialLogin: '/api/v3/public/user/social_login',
    register: '/api/v3/public/user/register',
    verifyOtp: '/api/v3/public/user/verify_otp',
    forgotPasscode: '/api/v3/public/user/passcode/forgot',
    resetPasscode: '/api/v3/public/user/passcode/reset',
    internal: {
      login: '/api/login',
      logout: '/api/logout',
      logoutV1: '/v1/user/logout',
      session: '/api/session',
      authSession: '/api/auth/session',
      authChecksum: '/api/auth/checksum',
    },
  },
  user: {
    profile: '/api/v3/private/user/self',
    refreshToken: '/api/v3/public/user/token/refresh',
  },
  supplier: {
    nearby: '/api/v3/private/supplier/nearby',
    profile: '/api/v3/private/supplier/profile',
    eta: '/web/supplier/eta',
    locations: '/web/supplier/locations',
  },
  order: {
    locateSupplier: `/v1/order/locate_supplier`,
    create: '/api/v3/private/order/create',
    createOrderRo: '/v3/order-gw/create',
    estimate: '/api/v3/private/order/estimate',
    detail: (order_id: string) => `api/v3/private/order/detail/${order_id}`,
    search: 'api/v3/private/order/search',
    publicRate: (order_id: string) => `/api/v3/public/order/${order_id}/rate`,
    list: '/api/v3/public/order/list',
  },
  tracking: {
    // Tracking link
    viaOrderId: (params: GetOrderTrackingViaOrderIdParams) =>
      `api/v3/public/order/${params.order_id}/tracking?user_id=${params.user_id}`, // get-order-tracking-via-order-id-fn.ts
    viaTrackingCode: (trackingCode: string) =>
      `api/v3/public/order/${trackingCode}/tracking`, // get-order-tracking-fn.ts
    viaTrackingNumber: (trackingNumber: string) =>
      `web/partner/order?tracking_number=${trackingNumber}`, // get-order-tracking-via-tracking-number-fn.ts

    // Tracking portal
    userLogin: '/api/v3/public/tracking-portal/user/login', // login-tracking-portal-fn.ts
    orderList: '/api/v3/private/tracking-portal/orders', // get-list-tracking-portal-order-fn.ts
    countOrders: '/api/v3/private/tracking-portal/orders/counting',
    orderDetail: (orderId: string) =>
      `/api/v3/private/tracking-portal/order/${orderId}`, // get-tracking-portal-order-fn.ts

    internal: {
      user: '/api/tracking-portal/user',
      orderList: '/api/tracking-portal',
      countOrders: '/api/tracking-portal/counting',
      orderDetail: (orderId: string) => `/api/tracking-portal/${orderId}`,
    },
    userProfile: '/api/v3/private/tracking-portal/user',
  },
  service: {
    detailById: (serviceId: string) => `api/v3/public/service/${serviceId}`,
    serviceTypes: '/api/v3/public/service/service-types',
  },
  warehouse: {
    list: 'api/v1/public/warehouse/warehouse_info',
    order: 'api/v3/private/warehouse/orders',
    meta: 'api/v3/private/warehouse/order/meta',
    assign: 'api/v3/private/warehouse/order/assign',
    cancel: 'api/v3/private/warehouse/order/cancel',
  },
  master_data: {
    pow: '/api/v3/public/master-data/proof-of-work',
    badge: '/api/v3/public/master-data/badge',
    service_detail: '/v1/order/service_detail',
    screen_configs: '/api/v3/public/master-data/screen-configs',
  },
  typesense: {
    searchSupplier: '/collections/supplier/documents/search',
  },
  osrm: {
    driving: 'https://route.ahamove.com/osrm/table/v1/driving',
  },
  route_optimization: {
    tsp: '/api/v3/private/route-optimization/tsp',
    vrp: 'https://gw-opstech-stg.ahamove.com/route-optimization/v1/vrp',
  },
  partner: {
    kaProfile: (token: string) =>
      `web/api/v2/partners/ka_profile?token=${token}`,
  },
  auto_complete: {
    getLocation: `https://ep.ahamove.com/stg/dplaces/web/v2/autocomplete`,
  },
  assets: {
    upload_image: '/api/v3/private/assets/images/upload',
    upload_order_image: '/api/v3/private/assets/order-images/upload',
  },
  place: {
    autocomplete: '/dplaces/web/v2/autocomplete',
    autocomplete_google: '/gplaces/web/v2/autocomplete',
    geocode_reverse: '/wgeo/v2/reverse',
    detail: 'gplaces/web/v2/detail',
    savedPlaces: '/api/v3/private/user/user-place',
    batchGeocode: 'wgeo/batch_geocode',
    geocode: 'wgeo/geocode',
    findAdmin: '/geo/v2/find_admin',
  },
  insurance: {
    item: {
      trends: '/api/v3/private/order/items/trends',
      suggestion: '/api/v3/private/order/items/autocomplete-items',
    },
  },
  promotion: {
    list_promotions: '/api/v3/private/promotion/list',
    promotion_detail: (promo_id: string) =>
      `/api/v3/private/promotion/${promo_id}`,
  },
  payment: {
    pay_order: '/api/v3/private/payment/pay/order',
    order: '/api/v3/private/payment/customers/methods/order',
    top_up: '/api/v3/private/payment/top-up',
    subscription: '/api/v3/private/payment/pay/subscription',
    get_list_payment_methods_topup: '/api/v3/private/payment/methods/top-up',
    add_bank_account: '/v1/user/add_bank_account',
    remove_bank_account: '/v1/user/remove_bank_account',
    update_balance: '/v1/user/update_balance',
    cod_transactions: '/api/v3/private/payment/customers/cod-transactions',
    binding: '/api/v3/private/payment/binding-wallet',
    zalopay: {
      check_remain: '/api/v3/private/payment/zalopay/check_remain',
      pay_order: '/api/v3/private/payment/zalopay/pay_order/',
    },
    shopeepay: {
      pay_token: '/api/v3/private/payment/pay-token',
    },
    reimbursement: '/api/v3/private/payment/pay/reimbursement',
    virtual_account: '/api/v3/private/payment/bank/virtual-account',
    access_token: '/api/v3/private/payment/access-token',
    withdrawal: '/api/v3/private/payment/withdrawal',
    withdrawal_cod: '/api/v3/private/payment/methods/withdrawal_cod',
    payment_customers: '/api/v3/private/payment/customers/methods/',
    source_cards: '/api/v3/private/payment/sources/cards',
    set_default_payment: '/api/v3/private/payment/customers/methods',
    delete_payment: '/api/v3/private/payment/sources/cards',
    transaction_by_id: (id: string) =>
      `/api/v3/public/payment/customers/transactions/${id}`,
  },
  intercity: {
    provinces: '/api/v3/public/intercity-order/provinces',
    districts: (provinceId: string) =>
      `/api/v3/public/intercity-order/provinces/${provinceId}/districts`,
    wards: (districtId: string) =>
      `/api/v3/public/intercity-order/districts/${districtId}/wards`,
    estimateBillableWeight:
      '/api/v3/private/intercity-order/billable-weight/estimates',
    transferCod: (orderId: string) =>
      `/api/v3/private/intercity-order/${orderId}/transfer-cod`,
    estimate: '/api/v3/private/intercity-order/estimates',
    create: '/api/v3/private/intercity-order/creates',
    cancel: (orderId: string) =>
      `/api/v3/private/intercity-order/${orderId}/cancel`,
    details: (orderId: string) => `/api/v3/private/intercity-order/${orderId}`,
  },
  testing_tool: {
    journey: (orderId: string) => `/v3/testing/orders/${orderId}/journey`,
    callback_log: (orderId?: string) =>
      `/v3/testing/orders/callback-logs?order_id=${orderId}`,
    get_cancel_reasons: `/api/v3/public/master-data/proof-of-work`,
    retry_callback: () => `/v3/testing/orders/callback/retry`,
  },
  onwheel: {
    create_account: '/api/v3/admin/tracking-portal/users',
    partner_setting_common: '/api/partner/setting-common',
    account_list: '/api/v3/admin/tracking-portal/users',
    tag_list: '/api/v3/admin/tracking-portal/tags',
    upadte_account: (userName: string) =>
      `/api/v3/admin/tracking-portal/users/${userName}`,
  },
  fb_graph: {
    exchange_code: '/oauth/access_token',
    me: '/me',
    accounts: '/me/accounts',
    get_page: (accessToken: string) =>
      `/me/accounts?access_token=${accessToken}`,
    userAccounts: (userId: string) => `/${userId}/accounts`,
  },
  ahafood: {
    get_info: 'api/v3/private/food-order/self',
    fb_login: 'api/v3/public/food-order/social-login',
    acquire_merchant: '/web/v1/public/ahmsites/form/acquire-merchant',
    append_page: (pageId: string) => `/v3/internal/page/facebook/${pageId}`,
  },
  firebase: {
    remoteConfig: '/web/firebase/remote_config',
  },
};
