import { apiRoutes } from '../../api-routes';
import { publicHttpClient } from '../../util/http-client';
import type { ForgotPassCode, ForgotPasscodeResponse } from './types';

export const forgotPasscodeFn = async (body: ForgotPassCode) => {
  const response = await publicHttpClient.post<ForgotPasscodeResponse>(
    apiRoutes.auth.forgotPasscode,
    {
      country_code: 'VN',
      ...body,
    }
  );
  return response.data;
};
