import { apiRoutes } from '../../api-routes';
import { publicHttpClient } from '../../util';
import type { TransactionResponse } from './types';

export const getPaymentCustomerTransactionFn = async (id: string) => {
  const response = await publicHttpClient.get<TransactionResponse>(
    apiRoutes.payment.transaction_by_id(id)
  );

  return response.data;
};
