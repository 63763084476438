import { apiRoutes } from '../../api-routes';
import { httpClient } from '../../util';
import type { IntercityPath } from '../place';
import type { IntercityPackageDetail, IntercityServiceRequest } from '../intercity/types';

export interface BillableWeightRequestBody {
  path: IntercityPath[];
  package_detail: IntercityPackageDetail[];
  service: IntercityServiceRequest;
}

export interface BillableWeightResponse {
  total_billable_weight: number;
  total_volumetric_weight: number;
  dim_factor: number;
  package_weight_details: PackageWeightDetail[];
  package_limits?: {
    min_weight: number;
    max_weight: number;
    min_length: number;
    max_length: number;
    min_width: number;
    max_width: number;
    min_height: number;
    max_height: number;
    min_quantity: number;
    max_quantity: number;
  };
}

export type PackageWeightDetail = {
  billable_weight: number;
  volumetric_weight: number;
  is_over_dimensional?: boolean;
};

export const estimateBillableWeightFn = async (
  body: BillableWeightRequestBody
) => {
  const response = await httpClient.post<BillableWeightResponse>(
    apiRoutes.intercity.estimateBillableWeight,
    body
  );

  return response.data;
};
