import { jwtDecode } from 'jwt-decode';

interface JwtTokenPayload {
  typ: string;
  cid: string;
  status: string;
  eoc: string;
  noc: string;
  cty: string;
  imei: string;
  type: string;
  exp: number;
  iat: number;
  iss: string;
  role_ids?: string[];
}
/**
 * A function to capitalize a string
 * @param s - A string to capitalize
 * @returns A string
 * @example
 * import { capitalizeString } from '@common-helpers/common-helpers';
 * const capitalized = capitalizeString('hello');
 * console.log(capitalized); // Hello
 * const capitalized = capitalizeString('ha noi');
 * console.log(capitalized); // Ha noi
 */
export function capitalizeString(s: string): string {
  return s.charAt(0).toUpperCase() + s.slice(1);
}

/**
 * A function to format a number to comma as a thousand separator (,) and dot as a decimal separator (.) and 2 decimal places (cents) by default (e.g. 1,000,000.00) or 0 decimal places (e.g. 1,000,000) if the number is an integer number
 * @param num - A number to format
 * @returns A string
 * @example
 * import { formatNumber } from '@common-helpers/common-helpers';
 * const formatted = formatNumber(1000000);
 * console.log(formatted); // 1,000,000.00
 * const formatted = formatNumber(1000000.123);
 * console.log(formatted); // 1,000,000.12
 */
export const formatNumber = (
  num = 0,
  locales: 'en-US' | 'vi-VN' = 'vi-VN'
): string => {
  return new Intl.NumberFormat(locales).format(num);
};

/**
 * A function to format a number to currency format with VND currency unit (đ) and comma as a thousand separator (,) and dot as a decimal separator (.) and 2 decimal places (cents) by default (e.g. đ1,000,000.00) or 0 decimal places (e.g. đ1,000,000) if the number is an integer number
 * @param num - A number to format
 * @param locales - A locale string from 'en-US' | 'vi-VN', default 'en-US'
 * @returns A string
 * @example
 * import { formatCurrency } from '@common-helpers/common-helpers';
 * const formatted = formatCurrency(1000000);
 * console.log(formatted); // đ1,000,000.00
 */
export const formatCurrency = (
  num = 0,
  locales: 'en-US' | 'vi-VN' = 'vi-VN'
): string => {
  const formattedValue = new Intl.NumberFormat(locales, {
    style: 'currency',
    currency: 'VND',
  }).format(num);

  // For 'vi-VN', manually move the currency symbol (₫) to the front
  if (locales === 'vi-VN') {
    return `đ${formattedValue.replace('₫', '').trim()}`;
  }

  return formattedValue;
};

/**
 * A function to repeat a string
 * @param s - A string to repeat
 * @param times - A number of times to repeat
 * @returns A string
 * @example
 * import { repeat } from '@common-helpers/common-helpers';
 *
 * const repeated = repeat('a', 3);
 * console.log(repeated); // aaa
 * const repeated = repeat('a', 0);
 * console.log(repeated); // ''
 * const repeated = repeat('a', -1);
 * console.log(repeated); // ''
 * const repeated = repeat('a', 1);
 * console.log(repeated); // a
 * const repeated = repeat('a', 2);
 * console.log(repeated); // aa
 */
function repeat(s: string, times: number): string {
  return new Array(times + 1).join(s);
}

/**
 * A function to pad a number with leading zeros
 * @param num - A number to pad
 * @param maxLength - A maximum length of the number
 * @returns A string
 * @example
 * import { pad } from '@common-helpers/common-helpers';
 *
 * const padded = pad(1, 3);
 * console.log(padded); // 001
 * const padded = pad(10, 3);
 * console.log(padded); // 010
 * const padded = pad(100, 3);
 * console.log(padded); // 100
 * const padded = pad(1000, 3);
 * console.log(padded); // 1000
 */
export const pad = (num: number, maxLength: number): string => {
  return repeat('0', maxLength - num.toString().length) + num;
};

/**
 * A function to load a JS file
 * @param src - A source of the JS file
 * @param attr - An object of attributes
 * @returns A promise
 * @example
 * import { loadJS } from '@common-helpers/common-helpers';
 * loadJS('https://code.jquery.com/jquery-3.6.0.min.js', {
 *  integrity: 'sha256-/SIrNqv8h6QGKDuNoLGA4iret+kyesCkHGzVUUV0shc=',
 * crossorigin: 'anonymous',
 * }).then(() => {
 * console.log('jQuery loaded');
 * });
 */
export const loadJS = (src: string, attr?: Record<string, any>) => {
  return new Promise((resolve, reject) => {
    const tag = document.createElement('script');
    tag.setAttribute('src', src);
    tag.setAttribute('type', 'text/javascript');
    if (attr) {
      for (const key in attr) {
        tag.setAttribute(key, String(attr[key]));
      }
    }
    tag.onload = resolve;

    tag.onerror = reject;

    document.head.appendChild(tag);
  });
};

/**
 * A function to clean accents from a string
 * @param str - A string to clean
 * @returns A string
 * @example
 * import { cleanAccents } from '@common-helpers/common-helpers';
 * const str = cleanAccents('Hà Nội');
 * console.log(str); // Ha Noi
 */
export const cleanAccents = (s: string): string => {
  let str = s;
  str = str.replace(/[àáạảãâầấậẩẫăằắặẳẵ]/g, 'a');
  str = str.replace(/[èéẹẻẽêềếệểễ]/g, 'e');
  str = str.replace(/[ìíịỉĩ]/g, 'i');
  str = str.replace(/[òóọỏõôồốộổỗơờớợởỡ]/g, 'o');
  str = str.replace(/[ùúụủũưừứựửữ]/g, 'u');
  str = str.replace(/[ỳýỵỷỹ]/g, 'y');
  str = str.replace(/đ/g, 'd');
  str = str.replace(/[ÀÁẠẢÃÂẦẤẬẨẪĂẰẮẶẲẴ]/g, 'A');
  str = str.replace(/[ÈÉẸẺẼÊỀẾỆỂỄ]/g, 'E');
  str = str.replace(/[ÌÍỊỈĨ]/g, 'I');
  str = str.replace(/[ÒÓỌỎÕÔỒỐỘỔỖƠỜỚỢỞỠ]/g, 'O');
  str = str.replace(/[ÙÚỤỦŨƯỪỨỰỬỮ]/g, 'U');
  str = str.replace(/[ỲÝỴỶỸ]/g, 'Y');
  str = str.replace(/Đ/g, 'D');
  // Combining Diacritical Marks
  str = str.replace(/[\u0300\u0301\u0303\u0309\u0323]/g, ''); // huyền, sắc, hỏi, ngã, nặng
  // eslint-disable-next-line no-misleading-character-class -- need to use \u02C6
  str = str.replace(/[\u02C6\u0306\u031B]/g, ''); // mũ â (ê), mũ ă, mũ ơ (ư)

  return str;
};

export const uniqueArray = (arr: any[] = []) => {
  const uniqueIds = new Set();
  return arr.filter((element: { _id: string } & Record<string, any>) => {
    // Add type annotation to the element parameter
    const isDuplicate = uniqueIds.has(element._id);
    uniqueIds.add(element._id);
    if (!isDuplicate) {
      return true;
    }
    return false;
  });
};

/**
 * A function to generate a letter avatar
 * @param name - A name to generate
 * @returns A string
 * @example
 * import { generateLetterAvatar } from '@common-helpers/common-helpers';
 * const avatar = generateLetterAvatar('John Doe');
 * console.log(avatar); // JD
 * const avatar = generateLetterAvatar('John');
 * console.log(avatar); // J
 * const avatar = generateLetterAvatar('John Doe Smith');
 * console.log(avatar); // JS
 */
export const generateLetterAvatar = (name = '') =>
  `${name.split(' ')[0]?.[0] ?? ''}${name.split(' ')[1]?.[0] ?? ''}`;

/**
 * A function to escape a string for use in RegExp
 * @param value - A string to escape
 * @returns A string
 * @example
 * import { escapeRegExp } from '@common-helpers/common-helpers';
 * const escaped = escapeRegExp('Hello. How are you?');
 * console.log(escaped); // Hello\. How are you\?
 * const regex = new RegExp(escaped);
 */
export const escapeRegExp = (value: string): string => {
  return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
};

/**
 * A function to get the difference between two arrays
 * @param arr1 - An array
 * @param arr2 - An array
 * @returns An array
 * @example
 * import { getDiffBetweenTwoArray } from '@common-helpers/common-helpers';
 *
 * const arr1 = ['a', 'b', 'c'];
 * const arr2 = ['b', 'c', 'd'];
 * const diff = getDiffBetweenTwoArray(arr1, arr2);
 * console.log(diff); // ['a', 'd']
 */
export const getDiffBetweenTwoArray = (arr1: string[], arr2: string[]) => {
  function getDifference(a: string[], b: string[]) {
    return a.filter((element) => {
      return !b.includes(element);
    });
  }

  return [...getDifference(arr1, arr2), ...getDifference(arr2, arr1)];
};

/**
 * A function to get a random id
 * @returns A random id
 * @example
 * import { randomId } from '@common-helpers/common-helpers';
 *
 * const id = randomId();
 * console.log(id); // 9e1b8b1a
 */
export const randomId = (): string => {
  const uint32 = window.crypto.getRandomValues(new Uint32Array(1))[0];
  return uint32?.toString(16) ?? '';
};

/**
 * A function to apply a function to an array
 * @param func - A function to apply
 * @param array - An array to apply the function
 * @returns A number
 * @example
 * import { applyToArray } from '@common-helpers/common-helpers';
 *
 * const array = [1, 2, 3];
 * applyToArray(Math.max, array); // 3
 * applyToArray(Math.min, array); // 1
 * applyToArray(Math.min, []); // Infinity
 */
export const applyToArray = (func: any, array: number[]): number =>
  // eslint-disable-next-line @typescript-eslint/no-unsafe-call
  func.apply(Math, array);

/**
 * A function to get a random integer between min and max
 * @param min - A minimum value
 * @param max - A maximum value
 * @returns A random integer
 * @example
 * import { getRandomInt } from '@common-helpers/common-helpers';
 * getRandomInt(1, 10); // 1, 2, 3, 4, 5, 6, 7, 8, 9, or 10
 */
export function getRandomInt(min: number, max: number) {
  const minNumber = Math.ceil(min);
  const maxNumber = Math.floor(max);
  return Math.floor(Math.random() * (maxNumber - minNumber + 1)) + minNumber;
}

/**
 * A function to debounce a function
 * @param fn - A function to debounce
 * @param ms - A number of milliseconds to debounce
 * @returns A debounced function
 * @example
 * import { debounce } from '@common-helpers/common-helpers';
 * const debouncedFn = debounce(() => console.log('debounced'), 1000);
 * debouncedFn();
 */
export const debounce = <T extends (...args: any[]) => void>(
  fn: T,
  ms = 166
) => {
  let timeoutId: ReturnType<typeof setTimeout>;
  return function (this: any, ...args: Parameters<T>) {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => {
      fn.apply(this, args);
    }, ms);
  };
};

/**
 * Convert a kebab-case string to camelCase
 * @param kebabCaseString - A kebab-case string
 * @returns A camelCase string
 * @example
 * import { kebabToCamel } from '@common-helpers/common-helpers';
 * kebabToCamel('kebab-case-string'); // 'kebabCaseString'
 */
export function kebabToCamel(kebabCaseString = ''): string {
  return kebabCaseString.replace(/-./g, (match) =>
    match.charAt(1).toUpperCase()
  );
}

/**
 * Convert a snake_case string to camelCase
 * @param snakeCaseString - A snake_case string
 * @returns A camelCase string
 * @example
 * import { snakeToCamel } from '@common-helpers/common-helpers';
 * snakeToCamel('snake_case_string'); // 'snakeCaseString'
 */
export function snakeToCamel(snakeCaseString = ''): string {
  return snakeCaseString.replace(/_./g, (match) =>
    match.charAt(1).toUpperCase()
  );
}

/**
 * Convert a kebab-case string to snake_case
 * @param kebabCaseString - A kebab-case string
 * @returns A snake_case string
 * @example
 * import { kebabToSnake } from '@common-helpers/common-helpers';
 * kebabToSnake('kebab-case-string'); // 'kebab_case_string'
 */
export function kebabToSnake(kebabCaseString = ''): string {
  return kebabCaseString.replace(/-/g, '_');
}

export type IsoDateString = string;
/**
 * Check if a string is a valid ISO date string
 * @param dateStr - A string to check
 * @returns A boolean value
 * @example
 * import { isIsoDateString } from '@common-helpers/common-helpers';
 *
 * isIsoDateString('2021-09-09T09:09:09.009Z'); // true
 * isIsoDateString('2021-09-09T09:09:09.009'); // false
 * isIsoDateString('2021-09-09T09:09:09'); // false
 * isIsoDateString('2021-09-09T09:09'); // false
 * isIsoDateString('2021-09-09T09'); // false
 * isIsoDateString('2021-09-09'); // false
 * isIsoDateString('2021-09'); // false
 * isIsoDateString('2021'); // false
 */
export const isIsoDateString = (dateStr: unknown): dateStr is IsoDateString => {
  if (
    typeof dateStr !== 'string' ||
    !/\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}Z/.test(dateStr)
  ) {
    return false;
  }
  try {
    const d = new Date(dateStr);
    return d.toISOString() === dateStr;
  } catch (e: unknown) {
    return false;
  }
};

/**
 * A function to convert a File/Blob to base64
 * @param file - A File/Blob object
 * @returns A promise that resolves to a base64 string
 * @example
 * import { fileToBase64 } from '@common-helpers/common-helpers';
 *
 * const file = new File(['foo'], 'foo.txt', {
 *  type: 'text/plain',
 * });
 * const base64String = await fileToBase64(file);
 * console.log(base64String);
 *
 * import logoImg from './logo.png';
 * const base64String = await fileToBase64(logoImg);
 * console.log(base64String);
 */
export async function fileToBase64(
  file: File
): Promise<string | ArrayBuffer | null> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => {
      resolve(reader.result);
    };
    reader.onerror = reject;
    reader.readAsDataURL(file);
  });
}

export const isServer = typeof window === 'undefined';

/**
 * A function to delay
 * @param ms - A number of milliseconds to delay
 * @returns A promise
 * @example
 * import { delay } from '@common-helpers/common-helpers';
 * await delay(1000);
 * console.log('delayed');
 * // delayed after 1 second
 * await delay(2000);
 * console.log('delayed');
 * // delayed after 2 seconds
 */
export const delay = (ms: number) =>
  new Promise((resolve) => {
    setTimeout(resolve, ms);
  });

/**
 * A function to detect the platform
 * @param userAgent - A user agent string
 * @returns A string
 * @example
 * import { detectPlatform } from '@common-helpers/common-helpers';
 * const platform = detectPlatform(navigator.userAgent);
 * console.log(platform); // Windows, MacOS, Linux, Android, iOS, or Unknown
 */
export function detectPlatform(userAgent: string): string {
  if (/android/i.test(userAgent)) {
    return 'Android';
  }

  if (/iPad|iPhone|iPod/.test(userAgent)) {
    return 'iOS';
  }

  if (/Win/.test(userAgent)) {
    return 'Windows';
  }

  if (/Mac/.test(userAgent)) {
    return 'MacOS';
  }

  if (/Linux/.test(userAgent)) {
    return 'Linux';
  }

  if (/CrOS/.test(userAgent)) {
    return 'Chrome OS';
  }

  return 'Unknown';
}

/**
 * A function to detect suffix
 * @param string, array of string <suffix>
 * @returns Boolean
 */
export function isGotSuffix(s: string, a: string[]) {
  return a.some((se) => s.includes(se));
}
export const decodeToken = (accessToken: string) => {
  let decoded: JwtTokenPayload | null = null;

  try {
    decoded = jwtDecode<JwtTokenPayload>(accessToken);
  } catch (error) {
    console.error('Invalid jwt token');
  }

  return decoded;
};

export const compressImage = async (file: File): Promise<File> => {
  const MAX_IMAGE_SIZE_MB = 2;
  const MAX_WIDTH = 800; // Adjust as needed
  const MAX_HEIGHT = 800; // Adjust as needed

  const fileSizeInMB = file.size / (1024 * 1024);

  if (fileSizeInMB <= MAX_IMAGE_SIZE_MB) {
    // No need to compress if the file size is already within the limit
    return file;
  }

  const loadImage = () =>
    new Promise<HTMLImageElement>((resolve) => {
      const image = new Image();
      image.onload = () => {
        resolve(image);
      };
      image.src = URL.createObjectURL(file);
    });

  const image = await loadImage();

  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');

  if (!ctx) {
    throw new Error('Could not create canvas context');
  }

  // Calculate new dimensions to maintain aspect ratio
  let newWidth = image.width;
  let newHeight = image.height;

  if (newWidth > MAX_WIDTH) {
    newWidth = MAX_WIDTH;
    newHeight = (image.height * MAX_WIDTH) / image.width;
  }

  if (newHeight > MAX_HEIGHT) {
    newWidth = (newWidth * MAX_HEIGHT) / newHeight;
    newHeight = MAX_HEIGHT;
  }

  canvas.width = newWidth;
  canvas.height = newHeight;

  ctx.drawImage(image, 0, 0, newWidth, newHeight);

  const compressedBlob = await new Promise<Blob>((resolve) => {
    canvas.toBlob((blob) => {
      if (blob) {
        resolve(blob);
      } else {
        throw new Error('Could not create blob from canvas');
      }
    }, file.type);
  });

  return new File([compressedBlob], file.name, { type: file.type });
};

export const getPhoneNumber = (userId: string): string | undefined => {
  if (!userId) return;

  return userId.replace(/^84/, '0');
};

/**
 *
 * decimalToDMS - Convert decimal degrees (DD) to degrees, minutes, seconds (DMS) format
 * @param decimal decimal degrees (DD)
 * @param type 'latitude' or 'longitude'
 * @returns DMS (Degrees, Minutes, Seconds) format
 *
 * @example
 * import { decimalToDMS } from '@ahm/common-helpers';
 * const latitude = 21.028511;
 * const longitude = 105.804817;
 * const latitudeDMS = decimalToDMS(latitude, 'latitude');
 * const longitudeDMS = decimalToDMS(longitude, 'longitude');
 * console.log(latitudeDMS); // 21°1'42.64" N
 * console.log(longitudeDMS); // 105°48'17.34" E
 */
export function decimalToDMS(
  decimal: number,
  type: 'latitude' | 'longitude'
): string {
  const degrees = Math.floor(Math.abs(decimal));
  const minutesDecimal = (Math.abs(decimal) - degrees) * 60;
  const minutes = Math.floor(minutesDecimal);
  const seconds = (minutesDecimal - minutes) * 60;

  let direction = '';
  if (type === 'latitude') {
    direction = decimal >= 0 ? 'N' : 'S';
  } else if (type === 'longitude') {
    direction = decimal >= 0 ? 'E' : 'W';
  }

  return `${degrees}°${minutes}'${seconds.toFixed(2)}" ${direction}`;
}

export const validPhonePattern = /^(0|\+?84)\d{9,10}$/;

export function svgToBase64(svgString: string): string {
  // Remove unnecessary spaces and line breaks
  const minimizedSvg = svgString.replace(/\s+/g, ' ').trim();

  // URL encode special characters
  const urlEncodedSvg = encodeURIComponent(minimizedSvg)
    .replace(/'/g, '%27')
    .replace(/"/g, '%22');

  // Create base64 encoded SVG
  return 'data:image/svg+xml;charset=utf-8,' + urlEncodedSvg;
}

export const parseSafeValue = (value?: string | number) => {
  if (!value) return '—';

  if (typeof value === 'string') {
    return value;
  }

  return formatNumber(value);
};

export const findLastIndex = <T>(
  array: (T | undefined)[],
  predicate: (value: T) => boolean
): number => {
  for (let i = array.length - 1; i >= 0; i--) {
    if (array[i] !== undefined && predicate(array[i] as T)) {
      return i;
    }
  }
  return -1;
};

export function isNumeric(value: string | null): boolean {
  if (typeof value !== 'string') return false; // Only process strings

  // Regular expression to check for valid numeric strings (integers or floats)
  const numericRegex = /^-?\d+(?:\.\d+)?$/;
  return numericRegex.test(value);
}

export function parseSafeNumber(value: string | null): number | null {
  if (!value) {
    return null;
  }

  // Remove leading and trailing double quotes if they exist
  value = value.replace(/^"|"$/g, '');

  if (!isNumeric(value)) {
    return null;
  }

  return Number(value);
}

/**
 * Parses a numerical value and formats it into a more readable string representation
 * using suffixes for thousands (K), millions (M), billions (B), or terabytes (T).
 *
 * @param {number} num - The numerical value to be parsed and formatted.
 * @param {string} [prefix] - An optional prefix to prepend to the formatted number.
 * @returns {string} The formatted number with the appropriate suffix and optional prefix.
 *
 * @example
 * parseNumerical(950);              // returns '950'
 * parseNumerical(1500);             // returns '1.5 K'
 * parseNumerical(2500000);          // returns '2.5 M'
 * parseNumerical(1000000000);       // returns '1 B'
 * parseNumerical(2000);             // returns '2 K'
 * parseNumerical(1000000, "Total"); // returns 'Total 1 M'
 * parseNumerical(500, "Count");     // returns 'Count 500'
 */

export function parseNumerical(num: number, prefix?: string): string {
  if (num === 0) return '0';

  const suffixes = ['', 'K', 'M', 'B', 'T'];
  const tier = Math.floor(Math.log10(Math.abs(num)) / 3);
  const parsedNum = num / Math.pow(1000, tier);
  const formattedNumber =
    `${parsedNum % 1 === 0 ? parsedNum.toFixed(0) : parsedNum.toFixed(1)} ${suffixes[tier]}`.trim();

  return prefix ? `${prefix} ${formattedNumber}` : formattedNumber;
}

type AnyObject = Record<string, any>;

export function isEmptyObject(value: AnyObject): boolean {
  return (
    typeof value === 'object' &&
    value !== null &&
    !Array.isArray(value) &&
    Object.keys(value).length === 0
  );
}

export function cleanObject(obj: AnyObject): AnyObject {
  return Object.fromEntries(
    Object.entries(obj).filter(
      ([, value]) =>
        value !== undefined &&
        value !== null &&
        value !== '' &&
        !isEmptyObject(value as AnyObject)
    )
  );
}

export type Predicate<T> = (item: T, index: number, array: T[]) => boolean;

export function findIndex<T>(
  array: (T | undefined)[],
  predicate: Predicate<T>
): number {
  if (!Array.isArray(array) || array.length === 0) {
    return -1; // Early exit for non-array or empty array
  }

  for (let i = 0; i < array.length; i++) {
    const item = array[i];
    if (item !== undefined && predicate(item, i, array as T[])) {
      return i; // Return index if predicate returns true
    }
  }

  return -1; // Return -1 if no match is found
}
