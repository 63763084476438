import { apiRoutes } from '../../api-routes';
import { httpClient, type AxiosRequestConfig } from '../../util';
import type { OrderDetail, OrderListHaveTotalRes, OrderListReq } from './types';

export const getOrderListFn = async (
  params?: OrderListReq,
  config: AxiosRequestConfig = {}
) => {
  const response = await httpClient.get<
    typeof params extends { total: true }
      ? OrderListHaveTotalRes
      : OrderDetail[]
  >(apiRoutes.order.list, {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    params,
    ...config,
  });
  return response.data;
};
