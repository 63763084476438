import { apiRoutes } from '../../api-routes';
import {
  publicHttpClient,
  type AxiosRequestConfig,
} from '../../util/http-client';
import type { FbDefaultParams } from './types';

export type FbExchangeCodeParams = {
  client_id: string;
  client_secret: string;
  code: string;
  redirect_uri: string;
};

export type FbExchangeCodeResponse = FbDefaultParams & {
  token_type: string;
};

export const fbExchangeCodeFn = async (
  params: FbExchangeCodeParams,
  config: AxiosRequestConfig = {}
) => {
  const response = await publicHttpClient.get<FbExchangeCodeResponse>(
    'https://graph.facebook.com/v20.0' + apiRoutes.fb_graph.exchange_code,
    {
      params,
      ...config,
    }
  );

  return response.data;
};
