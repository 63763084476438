import { apiRoutes } from '../../api-routes';
import {
  type AxiosRequestConfig,
  epHttpClient,
  type ApiConfig,
} from '../../util';
import type { GeocodeReverseData } from './types';
import { formatFeatureToAddress } from './utils';

export type GeocodeReverseParams = {
  lng: number;
  lat: number;
  profile?: string;
  'boundary.country'?: string;
  'boundary.circle.radius'?: string;
};

export const getGeocodeReverseFn = async (
  apiConfig: ApiConfig,
  params: GeocodeReverseParams,
  config: AxiosRequestConfig = {}
) => {
  const response = await epHttpClient(apiConfig).get<GeocodeReverseData>(
    apiRoutes.place.geocode_reverse,
    {
      params: {
        profile: 'ahamove',
        'boundary.country': 'VN',
        'boundary.circle.radius': '2000',
        ...params,
      },
      ...config,
    }
  );
  return formatFeatureToAddress(response.data.data);
};
