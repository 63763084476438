import { apiRoutes } from '../../api-routes';
import { httpClient } from '../../util';

export type CancelIntercityOrderRequestBody = {
  order_id: string;
  cancel_code: string;
  cancel_comment: string;
};

export const cancelIntercityOrderFn = async ({
  order_id: orderId,
  ...body
}: CancelIntercityOrderRequestBody) => {
  const response = await httpClient.post<NonNullable<unknown>>(
    apiRoutes.intercity.cancel(orderId),
    body
  );

  return response.data;
};
