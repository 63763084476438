import { apiRoutes } from '../../api-routes';
import { publicHttpClient } from '../../util';

export type RemoteConfig = {
  defaultValue: {
    value: string;
  };
  valueType: RemoteConfigValueType;
};

export enum RemoteConfigValueType {
  Boolean = 'BOOLEAN',
  JSON = 'JSON',
  String = 'STRING',
}

export type RemoteConfigResponse = Record<string, RemoteConfig>;

export const getRemoteConfigFn = async () => {
  const response = await publicHttpClient.get<RemoteConfigResponse>(
    apiRoutes.firebase.remoteConfig
  );

  return response.data;
};
