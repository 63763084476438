import { apiRoutes } from '../../api-routes';
import { httpClient, type AxiosRequestConfig } from '../../util/http-client';

export type SupplierNearbyParams = {
  lat: number;
  lng: number;
  service_id?: string;
  status?: 'OFFLINE' | 'ONLINE';
  limit?: number;
  banned_list?: string;
  last_activity?: number;
  exclude_tag?: string;
  location_time?: number;
  extra_cod?: number;
};

export type SupplierNearbyData = {
  _id: string;
  direction: string;
  lat: number;
  lng: number;
  prev_lat: number;
  prev_lng: number;
};

export const getSupplierNearbyFn = async (
  params: SupplierNearbyParams,
  config: AxiosRequestConfig = {}
) => {
  const response = await httpClient.get<SupplierNearbyData[]>(
    apiRoutes.supplier.nearby,
    {
      params,
      ...config,
    }
  );
  return response.data;
};
