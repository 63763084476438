// Ensure this file is treated as a module
export {};

declare global {
  interface Window {
    CustomEvent: typeof CustomEvent;
  }
}

(() => {
  if (typeof window !== 'undefined') {
    if (typeof window.CustomEvent === 'function') return; // If not IE, exit the function

    interface CustomEventParams {
      bubbles?: boolean;
      cancelable?: boolean;
      detail?: any;
    }

    // Create a polyfill for CustomEvent
    const CustomEvent = function (
      event: string,
      params?: CustomEventParams
    ): CustomEvent {
      params = params ?? {
        bubbles: false,
        cancelable: false,
        detail: undefined,
      };
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-member-access
      const evt = document.createEvent('CustomEvent');
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-member-access
      evt.initCustomEvent(
        event,
        params.bubbles,
        params.cancelable,
        params.detail
      );
      return evt;
    };

    // Ensure that CustomEvent.prototype is properly set
    CustomEvent.prototype = window.Event.prototype;

    // Assign the polyfilled CustomEvent to the window object
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    (window as any).CustomEvent = CustomEvent;
  }
})();
