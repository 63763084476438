import { apiRoutes } from '../../api-routes';
import { httpClient } from '../../util';
import type { PaymentMethodParams, PaymentMethodResponse } from './types';

export const getPaymentMethodsByCustomersFn = async (
  params: PaymentMethodParams
) => {
  const response = await httpClient.get<PaymentMethodResponse>(
    apiRoutes.payment.order,
    {
      params,
    }
  );

  if (response.data?.status === 'success') {
    return response.data.data;
  }

  return [];
};
