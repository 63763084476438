import { apiRoutes } from '../../api-routes';
import { publicHttpClient } from '../../util/http-client';

export type RegisterRequestBody = {
  name: string;
  mobile: string;
  email: string;
  country_code: string;
  time?: number;
  checksum?: string;
  saas_user?: boolean;
  categories?: UserCategory[];
  type?: string;
  firebase_sms_auth?: 'true';
  ahamove_sms_auth?: 'true';
  lat?: number;
  lng?: number;
  user_type?: string;
  referral_code?: string;
  facebook_id?: string;
  google_id?: string;
  appsflyer_id?: string;
  conversion_data?: Record<string, string | number | null | Date | undefined>;
  source?: string; // source | utm_source
  campaign?: string; // aff_sid
  click_id?: string; // aff_clickid
} & Record<string, string>;

export interface UserCategory {
  child_code: string[];
  code: string;
}

export interface RegisterResponse {
  _id: string;
  id: string;
  // eslint-disable-next-line @typescript-eslint/no-redundant-type-constituents -- because we don't sure about the type
  login_type: 'passcode' | 'passcode_only' | string;
  authenticate_type: string;
}

export const registerFn = async (body: RegisterRequestBody) => {
  const response = await publicHttpClient.post<RegisterResponse>(
    apiRoutes.auth.register,
    body
  );
  return response.data;
};
