import { decodeToken } from '@ahm/common-helpers';
import { deleteCookie, getCookie, setCookie, hasCookie } from 'cookies-next';
import type { OptionsType } from 'cookies-next/lib/types';
import dayjs from 'dayjs';
import { refreshAccessTokenFn } from '../api';
import {
  COOKIE_NAME__ACCESS_TOKEN,
  COOKIE_NAME__REFRESH_TOKEN,
  COOKIE_NAME__SESSION_ID,
  JWT_REFRESH_BEFORE_EXPIRE,
} from '../constants';

export const getSessionId = () => {
  try {
    return getCookie(COOKIE_NAME__SESSION_ID) ?? null;
  } catch (err) {
    return null;
  }
};

export const getAccessToken = () => {
  try {
    return getCookie(COOKIE_NAME__ACCESS_TOKEN) ?? null;
  } catch (err) {
    return null;
  }
};

export const getRefreshToken = () => {
  try {
    return getCookie(COOKIE_NAME__REFRESH_TOKEN) ?? null;
  } catch (err) {
    return null;
  }
};

export const storeSessionId = (sessionId: string): void => {
  try {
    setCookie(COOKIE_NAME__SESSION_ID, sessionId, {
      expires: dayjs().add(3, 'month').toDate(),
      domain: process.env.NEXT_PUBLIC_DOMAIN,
      path: '/',
    });
  } catch (error) {
    console.log(error);
  }
};

export const storeAccessToken = (
  accessToken: string,
  option?: OptionsType
): void => {
  try {
    setCookie(COOKIE_NAME__ACCESS_TOKEN, accessToken, {
      expires: dayjs().add(3, 'month').toDate(),
      domain: process.env.NEXT_PUBLIC_DOMAIN,
      path: '/',
      ...option,
    });
  } catch (error) {
    console.log(error);
  }
};

export const storeRefreshToken = (
  refreshToken: string,
  option?: OptionsType
) => {
  try {
    setCookie(COOKIE_NAME__REFRESH_TOKEN, refreshToken, {
      expires: dayjs().add(3, 'month').toDate(),
      domain: process.env.NEXT_PUBLIC_DOMAIN,
      path: '/',
      ...option,
    });
  } catch (error) {
    console.log(error);
  }
};

export const removeSessionId = () => {
  try {
    deleteCookie(COOKIE_NAME__SESSION_ID, {
      domain: process.env.NEXT_PUBLIC_DOMAIN,
      path: '/',
    });
  } catch (error) {
    console.log(error);
  }
};

export const removeAccessToken = (option?: OptionsType) => {
  try {
    deleteCookie(COOKIE_NAME__ACCESS_TOKEN, {
      domain: process.env.NEXT_PUBLIC_DOMAIN,
      path: '/',
      ...option,
    });
  } catch (error) {
    console.log(error);
  }
};

export const removeRefreshToken = () => {
  try {
    deleteCookie(COOKIE_NAME__REFRESH_TOKEN, {
      domain: process.env.NEXT_PUBLIC_DOMAIN,
      path: '/',
    });
  } catch (error) {
    console.log(error);
  }
};

export const getJWTToken = async (
  checkExpiredToken: boolean
): Promise<[string, boolean]> => {
  let token: string = getCookie(COOKIE_NAME__ACCESS_TOKEN) ?? '';
  const refreshKey = getCookie(COOKIE_NAME__REFRESH_TOKEN) ?? '';
  let isNewToken = false;

  if (token && checkExpiredToken) {
    try {
      const decodedToken = decodeToken(token);

      if (decodedToken) {
        if (
          decodedToken.exp &&
          new Date().getTime() / 1000 >
            decodedToken.exp - JWT_REFRESH_BEFORE_EXPIRE
        ) {
          const res = await refreshAccessTokenFn({
            refresh_token: refreshKey,
            type: [decodedToken.type, decodedToken.imei].join('-'),
            mobile: decodedToken.cid,
          });

          if (res.token) {
            token = res.token;
            isNewToken = true;
          }
        }
      } else {
        token = '';
      }
    } catch (e) {
      console.error(e);
    }
  }

  return [token, isNewToken];
};

export { deleteCookie, getCookie, setCookie, hasCookie };
