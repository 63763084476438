import { apiRoutes } from '../../../api-routes';
import { baseUrl, type COOKIE_NAME__SESSION_ID } from '../../../constants';
import { publicHttpClient } from '../../../util';
import type {
  LoginTrackingPortalRequestBody,
  LoginTrackingPortalResponse,
} from '../../tracking';

export const loginTrackingFn = async (body: LoginTrackingPortalRequestBody) => {
  const response = await publicHttpClient.post<
    LoginTrackingPortalResponse & { [COOKIE_NAME__SESSION_ID]: string }
  >(baseUrl + apiRoutes.auth.internal.login, body);

  return response.data;
};
