import { apiRoutes } from '../../api-routes';
import { httpClient } from '../../util';
import type { OptimizedRoutesResponse } from './types';

export const getOptimizeRoutesFn = async (optimizeStringParam: string) => {
  const response = await httpClient.get<OptimizedRoutesResponse>(
    `${apiRoutes.osrm.driving}/${optimizeStringParam}`
  );
  return response.data;
};
