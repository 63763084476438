// import { apiRoutes, httpClient } from '@ahm/api-wrappers-core';

import { apiRoutes } from '../../api-routes';
import { httpClient } from '../../util';

export interface CreateAccountRequestBody {
  user_name: string;
  password: string;
  phone: string;
  email: string;
  // name: string;
  tags?: string[];
  expire_time?: number;
  note?: string;
}

export interface CreateAccountOrderParams {
  body: CreateAccountRequestBody;
}

export interface CreateAccountResponse {
  _id: string;
  phone: string;
  email: string;
  name: string;
  partner_id: string;
  create_time: number;
  account_status: string;
  created_by: string;
  tags: string[];
  note: string;
}

export const createAccountFn = async ({ body }: CreateAccountOrderParams) => {
  const response = await httpClient.post<CreateAccountResponse>(
    apiRoutes.onwheel.create_account,
    body
  );

  return response.data;
};
