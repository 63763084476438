import { apiRoutes } from '../../api-routes';
import { publicHttpClient } from '../../util/http-client';
import type { Service } from '../service';

export type ServiceDetailParams = {
  format: string;
  service_id: string;
};

export const getServiceDetailFn = async (params: ServiceDetailParams) => {
  const response = await publicHttpClient.get<Service>(
    apiRoutes.master_data.service_detail,
    {
      params,
    }
  );

  return response.data;
};
