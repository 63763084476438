import { apiRoutes } from '../../api-routes';
import { publicHttpClient, type AxiosRequestConfig } from '../../util';
import type { TrackingPortalUser } from './login-tracking-portal-fn';

export const getTrackingPortalUserFn = async (
  config: AxiosRequestConfig = {}
) => {
  const response = await publicHttpClient.get<TrackingPortalUser>(
    apiRoutes.tracking.userProfile,
    {
      ...config,
    }
  );

  return response.data;
};
