import { apiRoutes } from '../../api-routes';
import { httpClient } from '../../util';
import type {
  OptimizeRoutesTspRequestBody,
  OptimizeRoutesTspResponse,
} from './types';

export const optimizeRoutesTspFn = async (
  body: OptimizeRoutesTspRequestBody
) => {
  const response = await httpClient.post<OptimizeRoutesTspResponse>(
    apiRoutes.route_optimization.tsp,
    body
  );
  return response.data;
};
