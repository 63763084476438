import type { AxiosRequestConfig } from 'axios';
import { apiRoutes } from '../../api-routes';
import { httpClient } from '../../util/http-client';
import type { AccountUserOrder } from './type';

export type GetListUserAccountOrderParams = Partial<{
  user_names: string;
  account_status: string;
  phone: string;
  name: string;
  tags: string;
  sort_create_time: string;
  sort_expire_time: string;
  page: number;
  size: number;
}>;

export async function getListUserAccountOrderFn(
  params: GetListUserAccountOrderParams,
  config: Omit<AxiosRequestConfig, 'params'> = {}
) {
  const response = await httpClient.get<{
    data: AccountUserOrder[];
    total: number;
  }>(apiRoutes.onwheel.account_list, {
    params,
    ...config,
  });

  return response.data;
}
