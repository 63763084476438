import { apiRoutes } from '../../api-routes';
import { type AxiosRequestConfig, httpClient } from '../../util';
import type { SavedPlace } from './types';
import { formatSavedPlaceToAddress } from './utils';

export const getUserPlacesFn = async (config: AxiosRequestConfig = {}) => {
  const { data } = await httpClient.get<SavedPlace[]>(
    apiRoutes.place.savedPlaces,
    { ...config }
  );

  return data.map((sp) => formatSavedPlaceToAddress(sp));
};
