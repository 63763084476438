import { apiRoutes } from '../../api-routes';
import { httpClient } from '../../util/http-client';
import type { LoginResponse } from './types';

export interface LogoutRequestBody {
  token: string;
}

export const logoutV1Fn = async (token: string) => {
  const params = { token: token };
  const response = await httpClient.get<LoginResponse>(
    apiRoutes.auth.internal.logoutV1,
    { params }
  );
  return response.data;
};
