import { apiRoutes } from '../../../api-routes';
import { accountsUrl } from '../../../constants';
import { publicHttpClient } from '../../../util';
import type { ApiDataStatus } from '../../types';
import type { AuthSession } from './get-auth-session-fn';

export const setAuthSessionFn = async (body: AuthSession) => {
  const response = await publicHttpClient.post<{ status: ApiDataStatus }>(
    accountsUrl + apiRoutes.auth.internal.authSession,
    body,
    {
      withCredentials: true,
    }
  );

  return response.data;
};
