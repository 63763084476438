import { apiRoutes } from '../../api-routes';
import {
  publicHttpClient,
  type AxiosRequestConfig,
} from '../../util/http-client';
import type {
  FbDefaultParams,
  FbUserAccount,
  FbUserAccountPaging,
} from './types';

export type FbUserAccountResponse = {
  data: FbUserAccount[];
  paging: FbUserAccountPaging;
};

export const fbGetUserAccountsFn = async (
  params: FbDefaultParams,
  config: AxiosRequestConfig = {}
) => {
  const response = await publicHttpClient.get<FbUserAccountResponse>(
    'https://graph.facebook.com/v20.0' + apiRoutes.fb_graph.accounts,
    {
      params,
      ...config,
    }
  );

  return response.data;
};
