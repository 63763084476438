import { apiRoutes } from '../../api-routes';
import {
  type AxiosRequestConfig,
  publicHttpClient,
} from '../../util/http-client';
import type { OrderTracking } from '../tracking';
import type { EtaSupplierLocate } from './types';

export const getSupplierETAlFn = async (
  orderId: OrderTracking['_id'],
  config: AxiosRequestConfig = {}
) => {
  const response = await publicHttpClient.get<EtaSupplierLocate>(
    apiRoutes.supplier.eta,
    {
      params: {
        order_id: orderId,
      },
      ...config,
    }
  );

  return response.data;
};
