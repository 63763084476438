export const baseUrl = process.env.NEXT_PUBLIC_APP_URI;
export const accountsUrl = process.env.NEXT_PUBLIC_ACCOUNTS_URI;
export const apiUrl = process.env.NEXT_PUBLIC_API_URI;
export const apiLoyaltyUrl = process.env.NEXT_PUBLIC_API_LOYALTY_URI ?? '';
export const apiCmsUrl = process.env.NEXT_PUBLIC_API_CMS;
export const epApiUrl = process.env.NEXT_PUBLIC_EP_API_URI;
export const goshopApiUrl = process.env.NEXT_PUBLIC_GOSHOP_API_URI;
export const adminOnwheelApiUrl = process.env.NEXT_PUBLIC_ADMIN_ONWHEEL_API_URI;
export const API_FETCH_TIMEOUT = 30;

export const assetPrefix =
  process.env.NEXT_PUBLIC_DOMAIN === 'localhost'
    ? ''
    : process.env.NEXT_PUBLIC_ASSET_PREFIX ?? '';

export const COOKIE_NAME__SESSION_ID = 'session_id';
export const COOKIE_NAME__ACCESS_TOKEN = 'token';
export const COOKIE_NAME__REFRESH_TOKEN = 'refresh_token';
export const COOKIE_NAME__PICKUP_LOCATION = 'pickup_location';
export const COOKIE_NAME__SELECTED_SERVICE_ID = 'service_id';
export const COOKIE_NAME__X_CLIENT_ID = 'X-Client-Id';
export const COOKIE_NAME__X_DEVICE_ID = 'X-Device-Id';
export const COOKIE_NAME__X_PHONE = 'X-Phone';
export const COOKIE_NAME__EXPIRES_MAX_AGE__3M = 60;
export const COOKIE_NAME__EXPIRES_MAX_AGE__6M = 120;
export const COOKIE_NAME__TRANSIT_INSURANCE_SPECIAL_REQUEST_UNCHECK =
  't_i_s_r_uncheck';

export const LOCAL_STORAGE_KEY__RECENT_LOCATION = 'recent_location';
export const LOCAL_STORAGE_KEY__FREQUENT_LOCATION = 'frequent_location';
export const LOCAL_STORAGE_KEY__LATEST_PAYMENT_METHOD = 'latest_payment_method';
export const LOCAL_STORAGE_KEY__SENT_ACTIVATE_CODE_PAYLOAD = 'sent_a_v_p';
export const LOCAL_STORAGE_KEY__FINGERPRINT = 'fp_pro';

export const SESSION_STORAGE_KEY__CURRENT_SEARCH_PARAMS =
  'ahm.tracking.current_search_params';

export const PAGE = {
  PARTNER: 'partner',
  ONWHEEL: 'onwheel',
  AHAMOVE: 'ahamove',
};

export const JWT_REFRESH_BEFORE_EXPIRE = 15; // seconds
export const ENABLE_JWT_REFRESH_TOKEN = true;

export const RAISE_LABEL = {
  USER_NOT_LOGIN_IN: 'USER_NOT_LOGIN_IN',
};

export enum ErrorCode {
  MissingRequiredInfo = 'MISSING_REQUIRED_INFO',
  NotEnoughCredit = 'NOT_ENOUGH_CREDIT',
  NotAuthorized = 'NOT_AUTHORIZED',
  TokenNotFound = 'TOKEN_NOT_FOUND',
  RefreshTokenHasExpired = 'REFRESH_TOKEN_HAS_EXPIRED',
  RefreshTokenNotFound = 'REFRESH_TOKEN_NOT_FOUND',
  InvalidData = 'INVALID_DATA',
  BadRequest = 'BAD_REQUEST',
  UserNotFound = 'USER_NOT_FOUND',
  WrongPasscode = 'WRONG_PASSCODE',
  WrongOtp = 'WRONG_OTP',
  BannedUser = 'BANNED_USER',
  DeactivatedUserWrongPasscode = 'DEACTIVATED_USER_WRONG_PASSCODE',
  TokenExpired = 'TOKEN_EXPIRED',
  InvalidFutureOrderTime = 'INVALID_FUTURE_ORDER_TIME',
  NotSupportAdvanceBooking = 'NOT_SUPPORT_ADVANCE_BOOKING',
  NewOrderNotAllowed = 'NEW_ORDER_NOT_ALLOW',
  ConcurrentOrderNotAllowed = 'CONCURRENT_ORDER_NOT_ALLOW',
  ServiceNotValidAtPickup = 'SERVICE_NOT_VALID_AT_PICKUP',
  InvalidPickupArea = 'INVALID_PICKUP_AREA',
  InvalidDeliveryArea = 'INVALID_DELIVERY_AREA',
  InvalidServiceHour = 'INVALID_SERVICE_HOUR',
  MerchantNotFound = 'MERCHANT_NOT_FOUND',
  MissingPathInfo = 'MISSING_PATH_INFO',
  InvalidMaxDistance = 'INVALID_MAX_DISTANCE',
  InvalidCodCustomer = 'INVALID_COD_CUSTOMER',
  InvalidMaxCod = 'INVALID_MAX_COD',
  InvalidMaxStopPoint = 'INVALID_MAX_STOP_POINT',
  InvalidMinStopPoint = 'INVALID_MIN_STOP_POINT',
  TotalPayNotValid = 'TOTAL_PAY_NOT_VALID',
  InvalidChildOrderStatus = 'INVALID_CHILD_ORDER_STATUS',
  ExistedParentOrder = 'EXISTED_PARENT_ORDER',
  PayerTypeNotAllowedWithMultipleDp = 'PAYER_TYPE_NOT_ALLOW_WITH_MULTIPLE_DP',
  TrackingPortalWrongUsernameOrPassword = 'TRACKING_PORTAL_WRONG_USERNAME_OR_PASSWORD',
  InternalServerError = 'INTERNAL_SERVER_ERROR',
  InvalidMobileNumber = 'INVALID_MOBILE_NUMBER',
  InvalidPaymentMethod = 'INVALID_PAYMENT_METHOD',
}

export const ERROR_CODE_OPSTECH = {
  RECORD_NOT_FOUND: {
    code: 400,
    message: 'record not found',
    status: 400,
  },
};
export enum TPL_TYPE {
  BASIC = 'basic',
  ASSIGN = 'assign',
  MPD = 'mpd',
}
export const ERROR_CODES = {
  MISSING_REQUIRED_INFO: {
    code: 'MISSING_REQUIRED_INFO',
    http_code: 400,
    title: 'Thiếu dữ liệu truyền vào',
    description: 'Dữ liệu truyền vào là bắt buộc',
    internal: '',
  },
  TRACKING_PORTAL_WRONG_USERNAME_OR_PASSWORD: {
    code: 'TRACKING_PORTAL_WRONG_USERNAME_OR_PASSWORD',
    http_code: 400,
    title: 'Username hoặc password không chính xác',
    description: 'Username hoặc password không chính xác',
    internal: '',
  },
  NOT_ENOUGH_CREDIT: {
    code: 'NOT_ENOUGH_CREDIT',
    description: 'Tài khoản không đủ tiền hoặc không phải là tài khoản công nợ',
    http_code: 406,
    internal: '',
    title: 'Not enough credit',
  },
  NOT_AUTHORIZED: {
    code: 'NOT_AUTHORIZED',
    description:
      'Không có quyền thực hiện thao tác này, vui lòng kiểm tra lại.',
    http_code: 401,
    title: 'Thao tác bị từ chối.',
  },
  TOKEN_NOT_FOUND: {
    code: 'TOKEN_NOT_FOUND',
    http_code: 404,
    title: 'Token not found',
    description: 'Token không tồn tại trong hệ thống',
    internal: '',
  },
  REFRESH_TOKEN_HAS_EXPIRED: {
    code: 'REFRESH_TOKEN_HAS_EXPIRED',
    http_code: 401,
    title: 'Token expired',
    description: 'Your token has expired, please sign in again.',
    internal: '',
  },
  REFRESH_TOKEN_NOT_FOUND: {
    code: 'REFRESH_TOKEN_NOT_FOUND',
    http_code: 404,
    description: 'Refresh token not found',
  },
  INVALID_DATA: {
    code: 'INVALID_DATA',
    http_code: 400,
    title: 'Invalid data',
    description: 'Dữ liệu gửi lên chưa đúng, vui lòng kiểm tra lại',
    internal: '',
  },
  BAD_REQUEST: {
    code: 'BAD_REQUEST',
    http_code: 400,
    title: 'Thông tin không hợp lệ.',
    description: 'Thông tin không hợp lệ, vui lòng kiểm tra lại.',
    internal: '',
  },
  INVALID_FUTURE_ORDER_TIME: {
    code: 'INVALID_FUTURE_ORDER_TIME',
    description:
      'Advanced booking time is not valid (e.g more than 1 month from now)',
    http_code: 406,
    internal: '',
    title: '',
  },
  NOT_SUPPORT_ADVANCE_BOOKING: {
    code: 'NOT_SUPPORT_ADVANCE_BOOKING',
    description: 'Service does not support advanced booking',
    http_code: 406,
    internal: '',
    title: '',
  },
  NEW_ORDER_NOT_ALLOW: {
    code: 'NEW_ORDER_NOT_ALLOW',
    description: 'User cannot create a new order due to an uncompleted order',
    http_code: 406,
    internal: '',
    title: '',
  },
  CONCURRENT_ORDER_NOT_ALLOW: {
    code: 'CONCURRENT_ORDER_NOT_ALLOW',
    description: 'User cannot create a new order due to an uncompleted order',
    http_code: 406,
    internal: '',
    title: '',
  },
  SERVICE_NOT_VALID_AT_PICKUP: {
    code: 'SERVICE_NOT_VALID_AT_PICKUP',
    description: 'Service does not available at the pickup point',
    http_code: 406,
    internal: '',
    title: '',
  },
  INVALID_PICKUP_AREA: {
    code: 'INVALID_PICKUP_AREA',
    description: 'Service does not available in this area',
    http_code: 406,
    internal: '',
    title: '',
  },
  INVALID_DELIVERY_AREA: {
    code: 'INVALID_DELIVERY_AREA',
    description: 'Service does not support delivery in this area',
    http_code: 406,
    internal: '',
    title: '',
  },
  INVALID_SERVICE_HOUR: {
    code: 'INVALID_SERVICE_HOUR',
    description: 'Service does not available at the moment',
    http_code: 406,
    internal: '',
    title: '',
  },
  MERCHANT_NOT_FOUND: {
    code: 'MERCHANT_NOT_FOUND',
    description: 'Nearby merchant cannot be found',
    http_code: 404,
    internal: '',
    title: '',
  },
  MISSING_PATH_INFO: {
    code: 'MISSING_PATH_INFO',
    description: 'Path does not contain enough stop points',
    http_code: 400,
    internal: '',
    title: '',
  },
  INVALID_MAX_DISTANCE: {
    code: 'INVALID_MAX_DISTANCE',
    description: 'Distance violates max_distance configured in service',
    http_code: 406,
    internal: '',
    title: '',
  },
  INVALID_COD_CUSTOMER: {
    code: 'INVALID_COD_CUSTOMER',
    description: 'User is not allowed to book COD order',
    http_code: 406,
    internal: '',
    title: '',
  },
  INVALID_MAX_COD: {
    code: 'INVALID_MAX_COD',
    description: 'Order COD exceeds max_cod of service or user max_cod',
    http_code: 406,
    internal: '',
    title: '',
  },
  INVALID_MAX_STOP_POINT: {
    code: 'INVALID_MAX_STOP_POINT',
    description:
      'Number of stop points in the path exceeds max_stop_point of service',
    http_code: 406,
    internal: '',
    title: '',
  },
  INVALID_MIN_STOP_POINT: {
    code: 'INVALID_MIN_STOP_POINT',
    description:
      'Number of stop points in the path is less than min_stop_point of service',
    http_code: 406,
    internal: '',
    title: '',
  },
  TOTAL_PAY_NOT_VALID: {
    code: 'TOTAL_PAY_NOT_VALID',
    description:
      'Value of total_pay passed by the client does not match the order total_pay',
    http_code: 406,
    internal: '',
    title: '',
  },
  INVALID_CHILD_ORDER_STATUS: {
    code: 'INVALID_CHILD_ORDER_STATUS',
    description: 'Child orders are not in IDLE status',
    http_code: 406,
    internal: '',
    title: '',
  },
  EXISTED_PARENT_ORDER: {
    code: 'EXISTED_PARENT_ORDER',
    description: 'Child order has already had a parent',
    http_code: 406,
    internal: '',
    title: '',
  },
  PAYER_TYPE_NOT_ALLOW_WITH_MULTIPLE_DP: {
    code: 'PAYER_TYPE_NOT_ALLOW_WITH_MULTIPLE_DP',
    description:
      'CASH_BY_RECIPIENT payment method does not support multiple stop point orders',
    http_code: 406,
    internal: '',
    title: '',
  },
  INTERNAL_SERVER_ERROR: {
    code: 'INTERNAL_SERVER_ERROR',
    http_code: 500,
    title: 'Internal server error',
    description: 'Internal server error',
    internal: '',
  },
  INVALID_MOBILE_NUMBER: {
    code: 'INVALID_MOBILE_NUMBER',
    description: 'Số điện thoại không hợp lệ',
    http_code: 406,
    internal: '',
    title: 'Số điện thoại không hợp lệ',
  },
  BANNED_USER: {
    extra_params: {
      countdown_in_second: 120,
    },
    code: 'BANNED_USER',
    http_code: 403,
    title: 'tài khoản tạm khóa',
    description:
      'Bạn đã nhập sai OTP 5 lần liên tiếp. Vì lí do bảo mật, bạn không thể đăng nhập ở thời điểm này. Vui lòng thử lại sau 02:00s nữa.',
    internal: '',
    trace_id: '',
  },
  INVALID_PAYMENT_METHOD: {
    code: 'INVALID_PAYMENT_METHOD',
    http_code: 406,
    title: 'Phương thức thanh toán không hợp lệ',
    description: 'Phương thức thanh toán không hợp lệ, vui lòng kiểm tra lại.',
  },
};

export enum DeliveryType {
  SameDay = 'SAMEDAY',
  Nationwide = 'NATIONWIDE',
  Instant = 'INSTANT',
  Truck = 'TRUCK',
  Rental = 'RENTAL',
  Ride = 'RIDE',
  Intercity = 'INTERCITY',
}

export enum OrderStatus {
  All = 'ALL',
  Verifying = 'VERIFYING',
  WaitingForRo = 'WAITING_FOR_RO',
  NeedReturnToPartnerAsap = 'NEED_RETURN_TO_PARTNER_ASAP',
  NeedReturnToPartnerEod = 'NEED_RETURN_TO_PARTNER_EOD',
  ReturnedToPartner = 'RETURNED_TO_PARTNER',
  IdleForRo = 'IDLE_FOR_RO',
  Confirming = 'CONFIRMING',
  Idle = 'IDLE',
  Paying = 'PAYING',
  Accepted = 'ACCEPTED',
  InProcess = 'IN PROCESS',
  Completed = 'COMPLETED',
  Assigning = 'ASSIGNING',
  Cancelled = 'CANCELLED',
  DeliveryFailed = 'DELIVERY_FAILED',
  Returned = 'RETURNED',
  InReturn = 'IN_RETURN',
  Failed = 'FAILED',
  PickedUp = 'PICKED_UP',
  WhPending = 'WH_PENDING',
  ReturnFailed = 'RETURN_FAILED',
  Boarded = 'BOARDED',
  Created = 'CREATED',
}

export const PAGE_INDEX = 0;
export const PAGE_SIZE = 24;

export enum PostMessageEvent {
  InitChannel = 'INIT_CHANNEL',
  RequestToken = 'REQUEST_TOKEN',
}

export const NEED_CONFIRM_PICKUP_PARTNERS = [
  'kerryexpress',
  'fahasa',
  'greenfeed',
  'sociolla',
  'fvc',
  'ka_warehouse',
];

export const USER_TAG = {
  MERCHANT_GOSHOP: 'MERCHANT_GOSHOP',
  RO_EXPERT: 'RO_EXPERT',
};

export const SERVICE_REQUESTS = {
  BOOLEAN: 'BOOLEAN',
  COMMISSION_DISTANCE_PERCENTAGE: 'COMMISSION_DISTANCE_PERCENTAGE',
  PER_UNIT: 'PER_UNIT',
  ITEM: 'ITEM',
  COMMISSION_PERCENTAGE: 'COMMISSION_PERCENTAGE',
  COMMISSION_FLAT: 'COMMISSION_FLAT',
  TIER: 'TIER',
  FRAGILE: 'FRAGILE',
  INSURANCE: 'INSURANCE',
  COD_INSURANCE: 'COD_INSURANCE',
};
export const MAX_ITEM_VALUE_DEFAULT = {
  TRUCK: 100000000,
  BIKE: 30000000,
};
export const REQUIRE_AUTH_EVENT = 'REQUIRE_AUTH';
