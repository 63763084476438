import type { DefaultQueryError } from '../types';

export enum LoginType {
  Partner = 'partner',
  OnWheel = 'saas',
  Web = 'web',
}
export type UserType = 'supplier' | 'user';
export interface User {
  _id: string;
  account_status: string;
  name: string;
  currency: string;
  email: string;
  country_code: string;
  referral_code: string;
  status: string;
  create_time: number;
  facebook_id: string;
  google_id: string;
  main_account: UserAccount;
  bonus_account: UserAccount;
  cod_account: UserAccount;
  city_id: string[];
  verified: boolean;
  extra: UserExtra;
  wallet_info: {
    bank: Bank;
    payme: Payme;
  };
  cash_wallet: {
    payme: Payme;
  };
  score: number;
  postpaid: null;
  firebase_token: string;
  last_login: number;
  last_activity: number;
  nickname?: string;
  activate_time: number;
  activation_code: string;
  activation_time: number;
  banned_list: string[];
  benefit_id: string;
  devices: UserDevice[];
  extra_services: string[];
  favorite_list: string[];
  has_first_order: boolean;
  id_name: string;
  max_stop_points: number;
  num_rating: number;
  points: number;
  rating: number;
  saas: boolean;
  tags: string[];
  user_type: UserType;
  verify_status: string;
  verify_time: number;
  partner?: string;
  avatar?: string;
  max_cod?: number;
  suspended_until?: number;
}

export type LoginResponse = {
  authenticate_type: string;
  id: string;
  login_type: string;
};

export type UserAccount = {
  VND: number;
};

export interface UserDevice {
  app?: string;
  fcm?: boolean;
  imei: string;
  push?: string;
  status?: string;
  time: number;
  type: string;
}

export interface UserExtra {
  auto_transfer_cod: {
    enable: boolean;
    payment_provider: string;
  };
  contact: null;
  contract: {
    type: string;
  };
  documents: {
    img_url: string;
    status: string;
    submit_time: number;
    type: string;
  }[];
  eWallet: UserExtraEwallet;
  invoice: {
    auto_issue: boolean;
  };
  is_auto_transfer_cod: boolean;
  log_benefit: string;
  log_verified: string;
  old_device_id: string;
  order_tags: string[];
  rank: string;
  wootric_count: number;
  wootric_time: number;
}

export interface UserExtraEwallet {
  zalo_pay?: {
    status: string;
  };
  shopeepay?: {
    status: string;
  };
}

export interface Payme {
  last_update: number;
  status: string;
}

export interface Bank {
  account_id: string;
  account_name: string;
  branch: string;
  last_update: number;
  name: string;
  virtual_account: VirtualAccount;
}

export interface VirtualAccount {
  id: string;
  name: string;
}

export interface JwtTokenPayload {
  typ: string;
  cid: string;
  status: string;
  eoc: string;
  noc: string;
  cty: string;
  imei: string;
  type: string;
  exp: number;
  iat: number;
  iss: string;
  role_ids?: string[];
}

export interface LoginWithPhoneNumberResponse {
  authenticate_type: string;
  id: string;
  login_type: string;
  _id: string;
}

export interface LoginWithPhoneNumber {
  mobile: string;
  source?: string;
  campaign?: string;
  click_id?: string;
  name?: string;
  email?: string;
  facebook_id?: string;
  google_id?: string;
  ref_code?: string;
  ref_name?: string;
  login_type?: LoginWithPhoneNumberType;
  resend?: boolean;
}

export enum LoginWithPhoneNumberType {
  Partner = 'partner',
  OnWheel = 'onwheel',
}

export enum LoginWithPhoneNumberErrorCode {
  UserNotFound = 'USER_NOT_FOUND',
  UserDeactivated = 'USER_DEACTIVATED',
  InvalidMobileNumber = 'INVALID_MOBILE_NUMBER',
  BadRequest = 'BAD_REQUEST',
}

export type LoginWithPhoneNumberError =
  DefaultQueryError<LoginWithPhoneNumberErrorCode>;

export interface LoginWithSocial {
  google_token?: string;
  facebook_token?: string;
  source?: string;
  campaign?: string;
  mobile?: string;
  name?: string;
  email?: string;
  facebook_id?: null;
  google_id?: null;
  ref_code?: string;
  ref_name?: string;
  imei: string;
}

export enum LoginWithSocialErrorCode {
  GoogleTokenExpired = 'GOOGLE_TOKEN_EXPIRED',
  FacebookTokenExpired = 'FACEBOOK_TOKEN_EXPIRED',
  NotAuthorized = 'NOT_AUTHORIZED',
  TokenNotFound = 'TOKEN_NOT_FOUND',
  UserNotFound = 'USER_NOT_FOUND',
}

export type LoginWithSocialError = DefaultQueryError<LoginWithSocialErrorCode>;

export interface LoginWithSocialResponse {
  // In-case has been registered before
  token: string;
  refresh_token: string;
  firebase_token?: string;
  user: {
    _id: string;
    language: string;
    email: string;
    id: string;
    name: string;
    type: string;
    city_id: string[];
  } & Record<string, any>;
  // In-case do not register before
  email?: string;
  id?: string;
  name?: string;
  type?: string;
  city_id?: string[];
}

export interface VerifyOTPLogin {
  activation_code: string;
  mobile: string;
  campaign?: string;
  email?: string;
  facebook_id?: string;
  google_id?: string;
  name?: string;
  ref_code?: string;
  ref_name?: string;
  source?: string;
}

export interface VerifyOTPLoginResponse {
  token: string;
}

export enum VerifyOTPErrorErrorCode {
  WrongActiveCode = 'WRONG_ACTIVE_CODE',
  ExpiredActiveCode = 'OTP_EXPIRED',
}

export type VerifyOTPError = DefaultQueryError<VerifyOTPErrorErrorCode>;

export interface ForgotPasscodeResponse {
  is_sent: boolean;
  created_time: number;
}

export interface ForgotPassCode {
  mobile: string;
}

export interface ResetPasscodeRequest {
  new_passcode: string;
}

export interface ResetPasscodeResponse {
  status: boolean;
}

export interface Supplier {
  account_status: string;
  area_id: string;
  bonus_account: {
    VND: number;
  };
  booknow_code: string;
  booknow_code_expired_until: number;
  cash_wallet: {
    payme: Payme;
  };
  city_id: string;
  cod_account: {
    VND: number;
  };
  country_code: string;
  create_time: number;
  currency: string;
  deposit_account: {
    VND: number;
  };
  email: string;
  // enabled_requests
  // :
  // []
  enabled_services: string[];
  extra: SupplierExtra;
  file_version: { avatar: null };
  language: string;
  last_activity: number;
  last_login: number;
  main_account: { VND: number };
  matching_settings: MatchingSettings;
  name: string;
  num_rating: number;
  owner: string;
  partner: string;
  rating: number;
  rec_order_point: number;
  referral_code: string;
  rest_rec_order_point: number;
  role: string;
  role_ids: string[];
  services: string[];
  status: string;
  tags: string[];

  // uniform_check: [];
  vehicle_type: string;
  wallet_info: {
    bank: Bank;
    payme: Payme;
  };
  _id: string;
}

export interface SupplierExtra {
  cod: number;
  icon_rank_url: string;
  // installed_package: [];
  number_of_supporter: number;
  old_device_id: string;
  // pois: [];
  // relatives: [{}, {}];
  supplier_point: number;
  supplier_rank: string;
  updated_time: number;
  vehicle_type: string;
  verified: boolean;
}

export interface MatchingSettings {
  auth_matching: {
    assign_type: string;
    city_id: string;
    cod: number;
    enable: boolean;
    last_update: number;
  };
  assign_order_nearby_destination: {
    cod: number;
    destination: {
      address: string;
      lat: number;
      lng: number;
      short_address: string;
    };
    enable: boolean;
    last_update: string;
  };
}

export interface AuthResponse {
  accessToken: string;
  userID: string;
  expiresIn: number;
  signedRequest: string;
  graphDomain: string;
  data_access_expiration_time: number;
}

export interface FBAuthResponse {
  authResponse: AuthResponse;
  status: 'connected' | 'not_authorized' | 'unknown';
}
