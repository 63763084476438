import {
  type AxiosRequestConfig,
  publicHttpClient,
  apiRoutes,
} from '@ahm/api-wrappers-core';

export type FBLoginRequestBody = {
  facebook_token: string;
};

export type UserProfile = {
  _id: string;
  email: string;
  status: string;
  name: string;
  facebook_id: string;
  facebook_token: string;
  create_time: number;
};

export type FBLoginResponse = {
  user: UserProfile;
  access_token: string;
};

export const fbLoginFn = async (
  reqBody: FBLoginRequestBody,
  config: AxiosRequestConfig = {}
) => {
  const response = await publicHttpClient.post<FBLoginResponse>(
    apiRoutes.ahafood.fb_login,
    reqBody,
    {
      ...config,
    }
  );

  return response?.data;
};
