import { apiRoutes } from '../../api-routes';
import { type ApiConfig, epHttpClient } from '../../util';
import type { BatchGeocodeReq, BatchGeoCode } from './types';

export const batchGeocodeFn = async (
  apiConfig: ApiConfig,
  body: BatchGeocodeReq
) => {
  const response = await epHttpClient(apiConfig).post<BatchGeoCode[]>(
    apiRoutes.place.batchGeocode,
    { ...body }
  );

  return response.data;
};
