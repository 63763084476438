import { apiRoutes } from '../../../api-routes';
import { baseUrl } from '../../../constants';
import { publicHttpClient } from '../../../util';
import type { ApiDataStatus } from '../../types';

export const logoutFn = async () => {
  const response = await publicHttpClient.post<{ status: ApiDataStatus }>(
    baseUrl + apiRoutes.auth.internal.logout
  );

  return response.data;
};
