import { apiRoutes } from '../../api-routes';
import {
  type AxiosRequestConfig,
  publicHttpClient,
} from '../../util/http-client';
import type { OrderTracking } from './type';
import { orderTrackingFromJSON } from './utils';

export const getOrderTrackingFn = async (
  trackingCode: string,
  config: AxiosRequestConfig = {}
) => {
  const response = await publicHttpClient.get<OrderTracking>(
    apiRoutes.tracking.viaTrackingCode(trackingCode),
    {
      ...config,
    }
  );

  return orderTrackingFromJSON(response.data);
};
