import './custom-event';
import { decodeToken } from '@ahm/common-helpers';
import axios from 'axios';
import { getCookie } from 'cookies-next';
import { stringify } from 'qs';
import {
  type DefaultQueryError,
  refreshAccessTokenFn,
  type RefreshTokenResponse,
} from '../api';
import { apiRoutes } from '../api-routes';
import {
  COOKIE_NAME__ACCESS_TOKEN,
  COOKIE_NAME__REFRESH_TOKEN,
  ENABLE_JWT_REFRESH_TOKEN,
  ErrorCode,
  JWT_REFRESH_BEFORE_EXPIRE,
  RAISE_LABEL,
  REQUIRE_AUTH_EVENT,
} from '../constants';
import { authStore } from './auth-store';
import type { HttpClientConfig, HttpClientInstance } from './http-client';

export const isTokenNotFound = (response: DefaultQueryError['response']) => {
  if (
    response &&
    ((response.data &&
      response.data.code === ErrorCode.TokenNotFound.toString()) ||
      (response.status === 404 && response?.data.message === 'TokenNotFound'))
  ) {
    return true;
  }

  return false;
};

export const isTokenExpired = (response: DefaultQueryError['response']) => {
  if (response) {
    const dataCODE = response.data?.code;

    if (
      ([ErrorCode.NotAuthorized, ErrorCode.TokenExpired] as string[]).includes(
        dataCODE
      ) ||
      response.data.message === 'TokenUnAuthorized'
    ) {
      return true;
    }
  }

  return false;
};

export const useOnFulfilledRequest = async (config: HttpClientConfig) => {
  config.rawConfig = JSON.parse(JSON.stringify(config)) as HttpClientConfig;

  const [token] = await getJWTToken(ENABLE_JWT_REFRESH_TOKEN);

  // if (!token && config.withCredentials) {
  if (!token) {
    // Cancel request if cannot get token
    dispatchRequireAuth();

    return {
      ...config,
      // eslint-disable-next-line import/no-named-as-default-member
      cancelToken: new axios.CancelToken((cancel) =>
        cancel(RAISE_LABEL.USER_NOT_LOGIN_IN)
      ),
    } as HttpClientConfig;
  }

  return updateAxiosConfig(config, token);
};

export const useOnRejectedResponse = async (error: DefaultQueryError) => {
  const { config, response } = error;

  const originalRequest = config as HttpClientConfig;

  // skip refresh token request, add retry attempts to avoid infinite loops
  if (
    ENABLE_JWT_REFRESH_TOKEN &&
    originalRequest &&
    !originalRequest.url?.includes(apiRoutes.user.refreshToken) &&
    !originalRequest._retry &&
    isTokenExpired(response)
  ) {
    try {
      const token = await refreshToken(
        (originalRequest?.headers?.Authorization as string | undefined) ?? ''
      );
      if (token && originalRequest.rawConfig) {
        const modifiedOriginalRequest = updateAxiosConfig(
          originalRequest.rawConfig,
          token
        );

        // Add flag to avoid infinite loops
        modifiedOriginalRequest._retry = true;

        return await axios.request({
          ...modifiedOriginalRequest,
          ...originalRequest,
        });
      } else {
        dispatchRequireAuth(true);
      }
    } catch (e) {
      // Log user out if persistent NOT_AUTHORIZED errors when try request with new token
      if (isTokenExpired((e as DefaultQueryError).response)) {
        dispatchRequireAuth(true);

        return Promise.reject(e);
      }
    }
  } else if (isTokenNotFound(response) || isTokenExpired(response)) {
    dispatchRequireAuth(true);
  }

  // Normal flow: return error to caller
  return Promise.reject(error);
};

export const installAxiosInterceptors = (instance: HttpClientInstance) => {
  // Interceptor request
  instance.interceptors.request.use(
    async (config) => await useOnFulfilledRequest(config),
    (error) => Promise.reject(error)
  );

  // Interceptor response
  instance.interceptors.response.use(
    (res) => res,
    async (error: DefaultQueryError) => await useOnRejectedResponse(error)
  );
};

export function updateAxiosConfig(config: HttpClientConfig, token: string) {
  config = { ...config };

  if (token) {
    // const method = config.method?.toLowerCase();

    // add token to header authorization
    config.headers = config.headers ?? {};
    config.headers.Authorization = `Bearer ${token}`;

    // add token
    // if (config.token_required) {
    //   switch (method) {
    //     case 'get':
    //     case 'delete': {
    //       const params: any = config.params || {};

    //       params.token = token;
    //       config.params = params;

    //       break;
    //     }
    //     case 'post':
    //     case 'put': {
    //       const data = config.data || {};

    //       data.token = token;
    //       config.data = data;

    //       break;
    //     }
    //     default:
    //   }
    // }
  }

  if (config.stringify_data && config.data) {
    config.data = stringify(config.data);
  }

  return config;
}

export const getJWTToken = async (
  shouldCheckIsExpiredToken: boolean
): Promise<[string, boolean]> => {
  let token = getCookie(COOKIE_NAME__ACCESS_TOKEN) ?? '';
  let isNewToken = false;

  if (token && shouldCheckIsExpiredToken) {
    try {
      const decodedToken = decodeToken(token);

      if (decodedToken) {
        if (
          decodedToken?.exp &&
          new Date().getTime() / 1000 >
            decodedToken.exp - JWT_REFRESH_BEFORE_EXPIRE
        ) {
          const refreshedToken = await refreshToken(token);

          if (refreshedToken) {
            token = refreshedToken;
            isNewToken = true;
          }
        }
      } else {
        token = '';
      }
    } catch (e) {
      console.error(e);
    }
  }

  return [token, isNewToken];
};

// eslint-disable-next-line sonarjs/cognitive-complexity
export const refreshToken = async (bearerToken: string) => {
  const decodedToken = decodeToken(bearerToken.replace('Bearer ', ''));
  const refreshKey = getCookie(COOKIE_NAME__REFRESH_TOKEN) ?? '';

  if (!decodedToken || !refreshKey) return;

  let response: RefreshTokenResponse | undefined;
  try {
    response = await refreshAccessTokenFn({
      refresh_token: refreshKey,
      type: [decodedToken.type, decodedToken.imei].join('-'),
      mobile: decodedToken.cid,
    });
  } catch (e) {
    dispatchRequireAuth(true);
    return;
  }

  return response?.token;
};

export const dispatchRequireAuth = (isTokenExpired = false) => {
  const logout = authStore.getState().logout;
  logout(isTokenExpired);
  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
  if (typeof window !== 'undefined' && (window as any).CustomEvent) {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-member-access
    const event = new (window as any).CustomEvent(REQUIRE_AUTH_EVENT, {
      detail: { isTokenExpired },
    });

    // eslint-disable-next-line @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-argument
    window.dispatchEvent(event);
  }
};
