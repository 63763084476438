import { apiRoutes } from '../../api-routes';
import { adminOnwheelApiUrl } from '../../constants';
import { publicHttpClient } from '../../util';

export const DEFAULT_AHAMOVE_PARTNER_CONFIG: PartnerConfig = {
  name: 'ahamove',
  featured_banner: {
    href: 'https://ahamove.onelink.me/fmLb/t6nqcyiz',
    // img_uri:
    //   'https://vncdn.ahamove.com/test/d2cf6947bcd7e213333732df2b35ed9ad604fd31.jpg',
    // dimensions: '1976/688',
    img_uri:
      'https://vncdn.ahamove.com/ahamoveimg/banners/order-tracking-new-9k-9-ma.png',
    dimensions: '1200/400',
  },
  logo: {
    default:
      'https://aha-cms-production.s3.ap-southeast-1.amazonaws.com/logo_0cca169cc6.svg',
  },
  marker: {
    href: 'https://ahamove.com',
    image:
      'https://aha-cms-production.s3.ap-southeast-1.amazonaws.com/logo_full_white_f11e71cb59.svg',
    theme: 'dark',
  },
};

export type PartnerConfig = {
  name: string;
  logo?: PartnerLogo;
  marker?: PartnerMarker;
  featured_banner?: PartnerBanner;
  banner?: PartnerBanner[];
  mapStyle?: string;
  show_order_price?: boolean;
};

export type PartnerBanner = {
  img_uri: string;
  href?: string;
  dimensions?: string;
  order?: number;
  isSponsored?: boolean;
};

export type PartnerLogo = {
  default: string;
  dark?: string;
  light?: string;
};

export type PartnerMarker = {
  image: string;
  href?: string;
  theme?: 'light' | 'dark';
};

export interface PartnerSettingCommonResponse {
  data: PartnerConfig;
}

export const getPartnerSettingCommonFn = async (
  partner: PartnerConfig['name']
) => {
  const response = await publicHttpClient.get<PartnerSettingCommonResponse>(
    adminOnwheelApiUrl + apiRoutes.onwheel.partner_setting_common,
    { params: { partner } }
  );

  if (response?.data?.data && Object.keys(response.data.data).length > 1) {
    return response.data.data;
  }

  return DEFAULT_AHAMOVE_PARTNER_CONFIG;
};
