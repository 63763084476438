import { apiRoutes } from '../../api-routes';
import { publicHttpClient } from '../../util';
import type { CancelReason } from './types';

export type GetCancelReason = {
  user_type: string;
  reason_type: string;
  service_id?: string;
  delivery_type?: string;
  status?: string;
  enable?: boolean;
  app_type?: string;
};

export const getCancelReasonsFn = async (params: GetCancelReason) => {
  const response = await publicHttpClient.get<CancelReason[]>(
    apiRoutes.testing_tool.get_cancel_reasons,
    {
      params,
    }
  );
  return response.data;
};
