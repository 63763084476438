import type { TrackingPortalUser } from '../..';
import { apiRoutes } from '../../../api-routes';
import { baseUrl } from '../../../constants';
import { type AxiosRequestConfig, publicHttpClient } from '../../../util';

export const getInternalTrackingUserFn = async (
  config: AxiosRequestConfig = {}
) => {
  const response = await publicHttpClient.get<TrackingPortalUser>(
    baseUrl + apiRoutes.tracking.internal.user,
    {
      ...config,
      withCredentials: true,
    }
  );

  return response.data;
};
