import { apiRoutes } from '../../api-routes';
import { publicHttpClient } from '../../util/http-client';
import type { LoginWithSocial, LoginWithSocialResponse } from './types';

export const socialLoginFn = async (body: LoginWithSocial) => {
  const response = await publicHttpClient.post<LoginWithSocialResponse>(
    apiRoutes.auth.socialLogin,
    {
      fcm: true,
      format: 'json',
      type: 'web',
      push: 'web',
      ...body,
    }
  );
  return response.data;
};
