import type { AxiosRequestConfig } from 'axios';
import { apiRoutes } from '../../api-routes';
import { publicHttpClient } from '../../util';
import type { Province } from '../intercity/types';

export const getProvincesFn = async (config: AxiosRequestConfig = {}) => {
  const response = await publicHttpClient.get<{
    data: Province[];
  }>(apiRoutes.intercity.provinces, {
    ...config,
  });

  return response.data.data;
};
