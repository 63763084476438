import { apiRoutes } from '../../api-routes';
import { httpClient } from '../../util';

export const transferCODIntercityOrderFn = async (orderId: string) => {
  const response = await httpClient.post<any>(
    apiRoutes.intercity.transferCod(orderId)
  );

  return response.data;
};
