import type { AxiosRequestConfig } from 'axios';
import { apiRoutes } from '../../api-routes';
import { publicHttpClient } from '../../util';
import type { District, Ward } from '../intercity/types';

export const getWardsFn = async (
  districtId: District['_id'],
  config: AxiosRequestConfig = {}
) => {
  const response = await publicHttpClient.get<{ data: Ward[] }>(
    apiRoutes.intercity.wards(districtId),
    {
      ...config,
    }
  );

  return response.data.data;
};
