import {
  type AxiosRequestConfig,
  publicHttpClient,
  goshopApiUrl,
  apiRoutes,
} from '@ahm/api-wrappers-core';

export interface AppendPageRequestBody {
  page_id: string;
  page_access_token: string;
  page_name?: string;
  status: string;
  update_at?: string;
  store_id?: string;
}

export interface AppendPageResponse {
  status_code: number;
}

export const appendPageMerchantFn = async (
  reqBody: AppendPageRequestBody,
  config: AxiosRequestConfig = {}
) => {
  const { page_id } = reqBody;

  const response = await publicHttpClient.put<AppendPageResponse>(
    goshopApiUrl + apiRoutes.ahafood.append_page(page_id),
    reqBody,
    {
      ...config,
    }
  );

  return response?.data;
};
