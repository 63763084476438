import { apiRoutes } from '../../api-routes';
import {
  type AxiosRequestConfig,
  publicHttpClient,
} from '../../util/http-client';
import type { Badge } from './type';

export const getBadgeByDeliveryType = async (
  delivery_type: string,
  config: AxiosRequestConfig = {}
) => {
  const response = await publicHttpClient.get<Badge[]>(
    apiRoutes.master_data.badge,
    {
      params: {
        delivery_type,
      },
      ...config,
    }
  );

  return response.data;
};
